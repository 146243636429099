/* eslint-disable react/require-default-props */
import React from 'react';
import propTypes from 'prop-types';
import './ImgFallback.css';

/**
 *
 * @param props
 */
function ImgFallback({ className, style }) {
  return (
    <div
      className={`${className} d-flex justify-content-center align-items-center w-100 h-100 bg-light text-center fallback`}
      style={style}
    >
      <h1 className='text-muted'>No Media!</h1>
    </div>
  );
}

ImgFallback.propTypes = {
  className: propTypes.string,
  style: propTypes.object,
};

export default ImgFallback;
