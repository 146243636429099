/* eslint-disable import/no-cycle */
import {
  ADD_TO_CART_CONFIGURABLE,
  ADD_TO_CART_SIMPLE,
  ASSIGN_CUSTOMER_TO_GUEST_CART,
  CREATE_EMPTY_CART_ID,
  GET_CUSTOMER_CART_COUNT,
  GET_CUSTOMER_CART_ID,
  GET_CART_DETAILS,
  CART_STORE_CONFIG,
  GET_LOYALTY_POINTS,
  ADD_LOYALTY_POINTS,
  GET_GIFT_DETAILS,
  ADD_GIFT_OPTIONS,
  UPDATE_ITEM,
  REMOVE_ITEM,
  GET_COUPONS,
  ADD_COUPON_TO_CART,
  REMOVE_COUPON_FROM_CART,
  REMOVE_GIFT_OPTIONS,
  UPDATE_SHIPPING_ADDRESS,
  SET_SHIPPING_METHODS_ON_CART,
  QUICK_BUY_BY_SKU,
  RETRIVE_CART,
  ADD_TO_CART_WITH_QUANTITY,
  ADD_TO_CART_CONFIGURABLE_WITH_QUANTITY,
  ADD_MULTIPLE_TO_CART_SIMPLE,
  GET_FREE_PRODUCTS,
  ADD_FREE_ITEMS,
  ADD_SAVE_FOR_LATER,
  RETRIVE_TO_CART,
  DELETE_SAVE_FOR_LATER,
  QUICK_BUY_BY_SKU_WITH_QTY,
  SET_SHIPPING_ADDRESS_TO_CART,
  ADD_PROMOTIONAL_AMOUNT,
  REMOVE_PROMOTIONAL_AMOUNT,
  ADD_MULTIPLE_COUPONS_TO_CART,
  REMOVE_MULTIPLE_COUPONS_FROM_CART,
  ADD_GB_WALLET,
  REMOVE_GB_WALLET,
  UPDATE_CART_FOR_OFFLINE,
  ADD_MULTIPLE_SAVE_FOR_LATER,
} from 'Gql/Cart';
import { client as postClient } from 'Routes/index';
import { setAuth } from 'Utils/setAuth';
import { client as getClient } from 'Gql';
import { CONFIG_CART_PROD, SIMPLE_PROD } from 'Utils/Strings';
import { IsB2BLogged } from 'B2B/Utils/IsB2B';
import { COMPANY } from 'B2B/Gql';

const getCustomerCartId = async () => {
  const response = await getClient.query({
    query: IsB2BLogged() ? COMPANY : GET_CUSTOMER_CART_ID,
    fetchPolicy: 'no-cache',
    context: setAuth(),
  });
  return response.data;
};

const createEmptyCartId = async () => {
  const response = await postClient
    .mutate({
      mutation: CREATE_EMPTY_CART_ID,
      context: setAuth(),
    })
    .catch((err) => {
      throw new Error(err);
    });

  return response.data.createEmptyCart;
};

const assignCustomerCart = async (state) => {
  const response = await postClient
    .mutate({
      mutation: ASSIGN_CUSTOMER_TO_GUEST_CART,
      variables: {
        destCartID: localStorage.getItem('cartID'),
        sourceCartID: state.cart.cartID,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.assignCustomerToGuestCart;
};

const getCartCount = async (state) => {
  const response = await getClient
    .query({
      query: GET_CUSTOMER_CART_COUNT,
      context: setAuth(),
      fetchPolicy: 'no-cache',
      variables: {
        id: state.cart.cartID,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.cart.total_quantity;
};

const addToCart = async (state, data) => {
  const response = await postClient
    .mutate({
      mutation: data.type === SIMPLE_PROD ? ADD_TO_CART_SIMPLE : ADD_TO_CART_CONFIGURABLE,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
        sku: data.sku,
        selected_options: data.selected_options,
        qty: data.qty || 1,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });

  return response.data.addProductsToCart;
};

const addToCartWithQuantity = async (state, data) => {
  const response = await postClient
    .mutate({
      mutation:
        data.type === SIMPLE_PROD
          ? ADD_TO_CART_WITH_QUANTITY
          : ADD_TO_CART_CONFIGURABLE_WITH_QUANTITY,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
        sku: data.sku,
        selected_options: data.selected_options,
        quantity: data.quantity,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });

  return response.data.addProductsToCart;
};

/*

INDEX.JS FILE

*/

const addMultipleToCart = async (state, data) => {
  const response = await postClient
    .mutate({
      mutation: ADD_MULTIPLE_TO_CART_SIMPLE,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
        cartItems: data.cartItems,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });

  return response.data.addProductsToCart;
};

const getCartDetails = async (state, id = null) => {
  const response = await getClient
    .query({
      query: GET_CART_DETAILS,
      context: setAuth(),
      variables: {
        id: id || state.cart.cartID || localStorage.getItem('cartID'),
      },
      fetchPolicy: 'no-cache',
    })

    .catch((err) => {
      throw new Error(err);
    });

  return response.data.cart;
};

const getCartStoreConfig = async () => {
  const response = await getClient
    .query({
      query: CART_STORE_CONFIG,
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.storeConfig;
};

const getLoyaltyPoints = async () => {
  const response = await getClient
    .query({
      query: GET_LOYALTY_POINTS,
      fetchPolicy: 'no-cache',
      context: setAuth(),
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.getEasyRewardsStatistic;
};

const addLoyaltyPoints = async (state, points) => {
  const response = await postClient
    .mutate({
      mutation: ADD_LOYALTY_POINTS,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
        loyaltyPoints: points,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.useRewardPoints;
};

const addPromotionalAmount = async (state) => {
  const response = await postClient
    .mutate({
      mutation: ADD_PROMOTIONAL_AMOUNT,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.applyStoreCreditToCart;
};

const removePromotionalAmount = async (state) => {
  const response = await postClient
    .mutate({
      mutation: REMOVE_PROMOTIONAL_AMOUNT,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.removeStoreCreditFromCart;
};

const addGbWallet = async (state) => {
  const response = await postClient
    .mutate({
      mutation: ADD_GB_WALLET,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.applyGBWalletToCart;
};

const removeGbWallet = async (state) => {
  const response = await postClient
    .mutate({
      mutation: REMOVE_GB_WALLET,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.removeGBWalletFromCart;
};

const getGiftDetails = async () => {
  const response = await postClient
    .query({
      query: GET_GIFT_DETAILS,
      context: setAuth(),
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data;
};

const addGiftOptions = async (state, uid) => {
  const response = await postClient
    .mutate({
      mutation: ADD_GIFT_OPTIONS,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
        gift_uid: uid,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.setGiftOptionsOnCart;
};

const removeGiftOptions = async (state) => {
  const response = await postClient
    .mutate({
      mutation: REMOVE_GIFT_OPTIONS,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.setGiftOptionsOnCart;
};

const removeItem = async (state, uid) => {
  const response = await postClient
    .mutate({
      mutation: REMOVE_ITEM,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
        productuid: uid,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.removeItemFromCart;
};

const updateItem = async (state, item) => {
  const response = await postClient
    .mutate({
      mutation: UPDATE_ITEM,
      context: setAuth(),
      variables: {
        cartID: item.cartID || state.cart.cartID,
        productuid: item.uid,
        qty: item.qty,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.updateCartItems;
};

const setShippingAddress = async (state, data) => {
  const response = await postClient
    .mutate({
      mutation: SET_SHIPPING_ADDRESS_TO_CART,
      context: setAuth(),
      variables: {
        cart_id: state.cart.cartID,
        shipping_addresses: data,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.setShippingAddressesOnCart;
};

const updateShippingAddress = async (state) => {
  const response = await postClient
    .query({
      query: UPDATE_SHIPPING_ADDRESS,
      context: setAuth(),
      variables: {
        id: state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.cart;
};

const getCoupons = async (state) => {
  const response = await postClient
    .query({
      query: GET_COUPONS,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.getCoupons;
};

const applyCoupons = async (state, couponCode, vpId) => {
  const response = await postClient
    .mutate({
      mutation: ADD_COUPON_TO_CART,
      context: setAuth(),
      variables: {
        cartID: vpId || state.cart.cartID,
        couponCode,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.applyCouponToCart;
};

const applyMultipleCoupons = async (state, args) => {
  const { coupons, vpId } = args;
  const response = await postClient
    .mutate({
      mutation: ADD_MULTIPLE_COUPONS_TO_CART,
      context: setAuth(),
      variables: {
        cartID: vpId || state.cart.cartID,
        couponCodes: coupons,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.applyCouponsToCart;
};

const removeCoupons = async (state, vpId) => {
  const response = await postClient
    .mutate({
      mutation: REMOVE_COUPON_FROM_CART,
      context: setAuth(),
      variables: {
        cartID: vpId || state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.removeCouponFromCart;
};

const removeMultipleCoupons = async (state, couponCodes) => {
  const response = await postClient
    .mutate({
      mutation: REMOVE_MULTIPLE_COUPONS_FROM_CART,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
        couponCodes,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.removeCouponsFromCart;
};

const setShippingMethod = async (state, shipping_method) => {
  const response = await postClient
    .mutate({
      mutation: SET_SHIPPING_METHODS_ON_CART,
      context: setAuth(),
      variables: {
        input: {
          cart_id: state.cart.cartID,
          shipping_methods: [{ carrier_code: shipping_method, method_code: shipping_method }],
        },
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response?.data?.setShippingMethodsOnCart;
};

const calculateBagDiscount = (newState) => {
  const total = newState.reduce((result, num) => {
    if (num.prices.price.value <= 0) return result + 0;
    if (num?.applied_tier_price) {
      return result + (num?.applied_tier_price?.discount?.amount_off || 0) * (num?.quantity || 0);
    }
    if (num?.__typename === CONFIG_CART_PROD) {
      return (
        result + num.configured_variant.price_range.maximum_price.discount.amount_off * num.quantity
      );
    }
    return result + num.product.price_range.maximum_price.discount.amount_off * num.quantity;
  }, 0);
  return total;
};

const quickBuyBySkuWithQty = async (data) => {
  const { sku, qty } = data;
  const response = await postClient
    .mutate({
      mutation: QUICK_BUY_BY_SKU_WITH_QTY,
      context: setAuth(),
      variables: {
        sku,
        qty,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response;
};

const quickBuyBySku = async (data) => {
  const response = await postClient
    .mutate({
      mutation: QUICK_BUY_BY_SKU,
      context: setAuth(),
      variables: {
        sku: data,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response;
};

const retriveCart = async (state) => {
  const response = await postClient
    .mutate({
      mutation: RETRIVE_CART,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response;
};

const getFreeProducts = async (state) => {
  const vpId = localStorage.getItem('vpCartID');
  const response = await getClient
    .query({
      query: GET_FREE_PRODUCTS,
      context: setAuth(),
      variables: {
        id: vpId || state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response;
};

const addFreeProducts = async (state, data) => {
  const response = await postClient
    .mutate({
      mutation: ADD_FREE_ITEMS,
      context: setAuth(),
      variables: {
        cartID: state.cart.cartID,
        promoItems: data.promoItems,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });

  return response;
};

const addSaveForLater = async (itemData, state) => {
  const response = await getClient
    .query({
      query: ADD_SAVE_FOR_LATER,
      context: setAuth(),
      variables: {
        cart_id: state.cart.cartID,
        item_uid: itemData.item_uid,
        child_sku: itemData.child_sku,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.addSaveForLater;
};

const addMultipleSaveForLater = async (state) => {
  const response = await getClient
    .query({
      query: ADD_MULTIPLE_SAVE_FOR_LATER,
      context: setAuth(),
      variables: {
        cart_id: state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.updateCartOOSItems;
};

const retriveToCart = async (itemData, state) => {
  const response = await getClient
    .query({
      query: RETRIVE_TO_CART,
      context: setAuth(),
      variables: {
        cart_id: state.cart.cartID,
        saveLaterUid: itemData.saveLaterUid,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
  return response.data.retriveToCart;
};

const deleteSaveForLater = async (itemData, state) => {
  const response = await getClient
    .query({
      query: DELETE_SAVE_FOR_LATER,
      context: setAuth(),
      variables: {
        cart_id: state.cart.cartID,
        saveLaterUid: itemData.saveLaterUid,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.deleteSaveForLater;
};

const updateCartForOffline = async (state) => {
  const response = await getClient
    .query({
      query: UPDATE_CART_FOR_OFFLINE,
      context: setAuth(),
      variables: {
        cart_id: state.cart.cartID,
      },
      fetchPolicy: 'no-cache',
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.updateCartForOfflineCredit;
};

const cartServices = {
  getCustomerCartId,
  createEmptyCartId,
  assignCustomerCart,
  getCartCount,
  addToCart,
  addMultipleToCart,
  getCartDetails,
  getCartStoreConfig,
  getLoyaltyPoints,
  addLoyaltyPoints,
  addPromotionalAmount,
  removePromotionalAmount,
  addGbWallet,
  getGiftDetails,
  addGiftOptions,
  removeGiftOptions,
  removeItem,
  updateItem,
  getCoupons,
  applyCoupons,
  applyMultipleCoupons,
  removeCoupons,
  removeMultipleCoupons,
  calculateBagDiscount,
  setShippingMethod,
  setShippingAddress,
  updateShippingAddress,
  quickBuyBySku,
  quickBuyBySkuWithQty,
  retriveCart,
  addToCartWithQuantity,
  getFreeProducts,
  addFreeProducts,
  addSaveForLater,
  addMultipleSaveForLater,
  retriveToCart,
  deleteSaveForLater,
  removeGbWallet,
  updateCartForOffline,
};

export default cartServices;
