/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import WishlistDropdownCard from './WishlistDropdownCard';
import './CartDropdown.css';
import './WishlistDropdown.css';

function WishlistDropdown() {
  const navigate = useNavigate();
  const wishlist = useSelector((state) => state.wishlist.wishlist);

  return (
    <div className='cartDropdown wishlistDropDown'>
      <div className='cart-pop-over'>
        <div className='cart-pop-over-arrow wishlist-pop-over-arrow' />
        <div className='cart-pop-over-arrow-select-aria' />
      </div>
      <h3>WISHLIST</h3>

      <div className='cartDropdownProductWrapper'>
        {wishlist.map((product) => (
          <WishlistDropdownCard key={product.uid} product={product} />
        ))}
      </div>

      <div className='dropdownFooter'>
        <button type='button' className='whiteCta' onClick={() => navigate('/wishlist')}>
          view all
        </button>
      </div>
    </div>
  );
}

export default WishlistDropdown;
