/* eslint-disable no-unused-vars */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import React, { lazy, useEffect } from 'react';
import { initializeAnalytics } from '@firebase/analytics';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import NotFound from 'Utils/NotFound';
import ProtectedRouter, { ProtectedB2BRoute } from 'Utils/ProtectedRouter';
import Layout from 'Components/Global/Layout';
import { app } from 'firebaseConfig';
import RightSideContent from 'Components/StaticPages/RightSideContent';
import CanonicalTag from 'Components/Global/CanonicalTag';
import B2B from 'B2B/Pages/B2B';
import { useSelector } from 'react-redux';
import { IsB2BEnable, IsB2BLogged } from 'B2B/Utils/IsB2B';
import { VIRTUAL_CART_ITEM } from 'Utils/Strings';
import Clarity from '@microsoft/clarity';
import AppSuspense from './Supense';

const HomePage = lazy(() => import('Pages/Home'));
const PreBuzz = lazy(() => import('Pages/PreBuzz'));
const ProductListingPage = lazy(() => import('Pages/ProductListing'));
const WishlistPage = lazy(() => import('Pages/Wishlist'));
const OnboardingPage = lazy(() => import('Pages/Onbording'));
const CareerPage = lazy(() => import('Pages/Career'));
const SiteMapPage = lazy(() => import('Pages/SiteMap'));
const PrivacyPoliciesPage = lazy(() => import('Pages/PrivacyPolicies'));
const TermsAndConditions = lazy(() => import('Pages/TermsAndConditions'));
const ContactUsPage = lazy(() => import('Pages/ContactUs'));
const Cart = lazy(() => import('Pages/Cart'));
const CommunityPage = lazy(() => import('Pages/Community'));
const GiftPage = lazy(() => import('Pages/Gifts'));
const ProductDetailPage = lazy(() => import('Pages/ProductDetails'));
const ProfilePage = lazy(() => import('Pages/Profile'));
const MyWishListPage = lazy(() => import('Pages/Profile/Wishlist'));
const QuidCredit = lazy(() => import('B2B/Components/QuidCredit/index'));
const MilestoneDiscountWrapper = lazy(() =>
  import('B2B/Components/PeriodDiscount/MilestoneDiscountWrapper'),
);
const GlamourBookVault = lazy(() => import('Pages/Profile/GlamourBookVault'));
const GlamourBookCash = lazy(() => import('Pages/Profile/GlamourbookCash'));
const GstInputCredit = lazy(() => import('B2B/Pages/profile/GstInputCredit'));
const LoyaltyPointPage = lazy(() => import('Pages/Profile/LoyalityPoints'));
const ReferPage = lazy(() => import('Pages/Profile/Refer'));
const OrderPage = lazy(() => import('Pages/Profile/Order'));
const OrderDetails = lazy(() => import('Pages/Profile/OrderDetails'));
const ManageNotifPage = lazy(() => import('Pages/Profile/ManageNotification'));
const CommunityDetailsPage = lazy(() => import('Pages/CommunityDetails'));
const OrderConfirm = lazy(() => import('Pages/OrderConfirmed'));
const OrderFailure = lazy(() => import('Pages/OrderFailure'));
const CategoryLandingPage = lazy(() => import('Pages/CategoryLanding'));
const BrandLandingPage = lazy(() => import('Pages/BrandLanding'));
const BrandStorePage = lazy(() => import('Pages/BrandStore'));
const Address = lazy(() => import('Pages/Profile/Address'));
const MyProfileDescription = lazy(() => import('Components/MyProfile/MyProfileDescription'));
const CartAddress = lazy(() => import('Pages/Cart/Address/index'));
const CmsPage = lazy(() => import('Pages/Page'));
const AccountDeleted = lazy(() => import('Pages/AccountDeleted/index'));
const DeleteYourAccount = lazy(() => import('Pages/DeleteYourAccount/index'));
const GetApp = lazy(() => import('Pages/GetApp/index'));
const VoucherDetails = lazy(() => import('Pages/VoucherDetails/index'));
const QRValidate = lazy(() => import('Pages/QRValidate/index'));
const CheckoutAddress = lazy(() => import('Pages/Checkout/Address/index'));
const SamerKhouzami = lazy(() => import('Pages/SamerKhouzami'));
const TicketDownload = lazy(() => import('Pages/TicketDownload/TicketDownload'));
const BloomDeleteAccount = lazy(() => import('Pages/BloomDeleteAccount/index'));
const BloomPrivacyPolicy = lazy(() => import('Pages/BloomPrivacyPolicy/index'));
const Faq = lazy(() => import('Pages/FAQ/index'));
const FaqTopics = lazy(() => import('Pages/FAQ/Topics/index'));
const FaqTopicContent = lazy(() => import('Pages/FAQ/TopicContent/index'));

const reload = () => window.location.reload();

const customFetch = (uri, options) => {
  const token = localStorage.getItem('token');
  if (options.method === 'POST') {
    // eslint-disable-next-line no-param-reassign
    options.headers = {
      ...options.headers,
      authorization: token ? `Bearer ${token}` : '',
    };
  } else if (options.method === 'GET') {
    // eslint-disable-next-line no-param-reassign
    options.headers = {
      ...options.headers,
      'Content-Type': 'text/plain',
      'Sec-Fetch-Mode': 'no-cors',
    };
  }
  return fetch(uri, options);
};

// Post apollo provider
const requestMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      ...(localStorage.getItem('token') && {
        Authorization: `bearer ${localStorage.getItem('token')}`,
      }),
      'X-Magento-Cache-Id': localStorage.getItem('magentoCacheId') || '',
    },
  });
  return forward(operation);
});

export const client = new ApolloClient({
  link: concat(
    requestMiddleware,
    new HttpLink({
      uri: process.env.REACT_APP_API,
      fetch: customFetch,
      ...(!IsB2BLogged() && { useGETForQueries: true }),
    }),
  ),
  cache: new InMemoryCache(),
});

/**
 *
 */
function AppRouter() {
  const tagManagerArgs = {
    gtmId: 'GTM-5S85R77',
  };

  const isB2BAllowedState = useSelector((state) => state?.storeConfig?.isB2BAllowed);
  const launchDateTime = useSelector((state) => state.storeConfig.launch_date);
  const serverDateTime = useSelector((state) => state.storeConfig.server_time);
  const cartItems = useSelector((state) => state.cart.cart);
  const quidAccess = useSelector((state) => state.cart.quidAccess);
  const quidAccessStatus = useSelector((state) => state.cart.quidAccessStatus);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const b2bExceptions = [
      '/business',
      '/business/b2btermsandconditions',
      '/business/b2bprivacypolicies',
    ];

    if (IsB2BLogged()) {
      if (!location.pathname.startsWith('/business')) {
        navigate(
          `/business${location.pathname.length > 1 ? location.pathname : ''}${location.search}`,
          { replace: true },
        );
      }
    } else if (
      !IsB2BEnable() ||
      (location.pathname.startsWith('/business') &&
        !b2bExceptions.some((exception) => exception === location.pathname))
    ) {
      navigate(
        `${
          location.pathname.replace('/business', '').length > 1
            ? location.pathname.replace('/business', '')
            : ''
        }${location.search}`,
        { replace: true },
      );
    }
  }, [location.pathname, location.search, navigate]);

  TagManager.initialize(tagManagerArgs);
  useEffect(() => {
    Clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_ID);
    if (app.name) {
      initializeAnalytics(app);
    }
  }, []);

  return (
    <>
      {/* <SentryRoutes> */}
      <CanonicalTag />
      <Routes>
        {/* Needs to be uncommented to enable pre buzz */}
        {/* {getDateTwoDaysBefore(launchDateTime) > serverDateTime ? ( */}
        {false ? (
          <>
            <Route
              path='/'
              element={
                <AppSuspense>
                  <PreBuzz />
                </AppSuspense>
              }
            />
            <Route path='*' element={<NotFound />} />
          </>
        ) : (
          <>
            <Route
              path='/prebuzz'
              element={
                <AppSuspense>
                  <PreBuzz />
                </AppSuspense>
              }
            />
            <Route
              exact
              path='lebloom-delete-account'
              element={
                <AppSuspense>
                  <BloomDeleteAccount />
                </AppSuspense>
              }
            />
            <Route
              exact
              path='lebloom-privacy-policy'
              element={
                <AppSuspense>
                  <BloomPrivacyPolicy />
                </AppSuspense>
              }
            />
            <Route path={`${IsB2BLogged() ? 'business' : ''}`} element={<Layout />}>
              <Route
                path='faq'
                element={
                  <AppSuspense>
                    <FaqTopics />
                  </AppSuspense>
                }
              >
                <Route index element={<Faq />} />
                <Route path=':topic' element={<FaqTopicContent />} />
              </Route>
              <Route
                path='voucherdetails'
                element={
                  <AppSuspense>
                    <VoucherDetails />
                  </AppSuspense>
                }
              />
              {isB2BAllowedState && (
                <Route path='business'>
                  <Route
                    exact
                    path=''
                    element={
                      <ProtectedB2BRoute isAllowed={!IsB2BLogged()}>
                        <AppSuspense>
                          <B2B />
                        </AppSuspense>
                      </ProtectedB2BRoute>
                    }
                  />
                  <Route
                    path=''
                    element={
                      <AppSuspense>
                        <ContactUsPage />
                      </AppSuspense>
                    }
                  >
                    <Route
                      path=':pageID'
                      element={
                        <AppSuspense>
                          <RightSideContent />
                        </AppSuspense>
                      }
                    />
                  </Route>
                </Route>
              )}
              <Route
                path='qrvalidate'
                element={
                  <AppSuspense>
                    <QRValidate />
                  </AppSuspense>
                }
              />
              <Route
                path='ticketdownload'
                element={
                  <AppSuspense>
                    <TicketDownload />
                  </AppSuspense>
                }
              />
              {/* <Routes> */}
              <Route path='*' element={<NotFound />} />
              <Route path='public/.well-known/apple-app-site-association' onEnter={reload} />
              <Route
                path=''
                element={
                  <AppSuspense>
                    <HomePage />
                  </AppSuspense>
                }
              />
              <Route
                path='referral'
                element={
                  <AppSuspense>
                    <HomePage />
                  </AppSuspense>
                }
              />
              <Route
                path='account-deleted'
                element={
                  <AppSuspense>
                    <AccountDeleted />
                  </AppSuspense>
                }
              />
              <Route path='academy/samerkhouzami'>
                <Route
                  path=''
                  element={
                    <AppSuspense>
                      <SamerKhouzami />
                    </AppSuspense>
                  }
                />
                <Route path='checkout'>
                  <Route
                    exact
                    path='address'
                    element={
                      <ApolloProvider client={client}>
                        <AppSuspense>
                          <CheckoutAddress />
                        </AppSuspense>
                      </ApolloProvider>
                    }
                  />
                </Route>
              </Route>
              <Route
                path='product-listing'
                element={
                  <AppSuspense>
                    <ProductListingPage />
                  </AppSuspense>
                }
              >
                <Route
                  exact
                  path=':cat1/:cat2/:cat'
                  element={
                    <AppSuspense>
                      <ProductListingPage />
                    </AppSuspense>
                  }
                />
                <Route
                  exact
                  path=':cat1/:cat2/'
                  element={
                    <AppSuspense>
                      <ProductListingPage />
                    </AppSuspense>
                  }
                />
                <Route
                  exact
                  path=':cat1'
                  element={
                    <AppSuspense>
                      <ProductListingPage />
                    </AppSuspense>
                  }
                />
              </Route>
              <Route
                path='wishlist'
                element={
                  <AppSuspense>
                    <WishlistPage />
                  </AppSuspense>
                }
              />
              <Route
                path='onboarding'
                element={
                  <AppSuspense>
                    <OnboardingPage />
                  </AppSuspense>
                }
              />
              <Route
                path='careers'
                element={
                  <AppSuspense>
                    <CareerPage />
                  </AppSuspense>
                }
              />
              <Route
                path='site-map'
                element={
                  <AppSuspense>
                    <SiteMapPage />
                  </AppSuspense>
                }
              />
              <Route
                path='privacy-policies'
                element={
                  <AppSuspense>
                    <PrivacyPoliciesPage />
                  </AppSuspense>
                }
              />
              <Route
                path='terms-and-conditions'
                element={
                  <AppSuspense>
                    <TermsAndConditions />
                  </AppSuspense>
                }
              />
              <Route
                path=''
                element={
                  <AppSuspense>
                    <ContactUsPage />
                  </AppSuspense>
                }
              >
                <Route
                  path=':pageID'
                  element={
                    <AppSuspense>
                      <RightSideContent />
                    </AppSuspense>
                  }
                />
              </Route>
              <Route path='shopping-cart'>
                <Route
                  path=''
                  element={
                    <ApolloProvider client={client}>
                      <AppSuspense>
                        <Cart />
                      </AppSuspense>
                    </ApolloProvider>
                  }
                />
                <Route
                  path='address'
                  element={
                    <AppSuspense>
                      <CartAddress />
                    </AppSuspense>
                  }
                />
                <Route
                  path='checkout'
                  element={
                    <AppSuspense>
                      <CartAddress />
                    </AppSuspense>
                  }
                />
              </Route>
              <Route
                exact
                path='category/community'
                element={
                  <AppSuspense>
                    <CommunityPage />
                  </AppSuspense>
                }
              />
              <Route
                exact
                path='category/gifts'
                element={
                  <AppSuspense>
                    <GiftPage />
                  </AppSuspense>
                }
              />
              <Route
                path='category/:cat1'
                element={
                  <AppSuspense>
                    <CategoryLandingPage />
                  </AppSuspense>
                }
              />
              <Route
                path=':name/:proId'
                element={
                  <AppSuspense>
                    <ProductDetailPage />
                  </AppSuspense>
                }
              />
              <Route
                path=''
                element={
                  <AppSuspense>
                    <ProductDetailPage />
                  </AppSuspense>
                }
              >
                <Route
                  exact
                  path=':cat1/:cat2/:cat/:name/:proId'
                  element={
                    <AppSuspense>
                      <ProductDetailPage />
                    </AppSuspense>
                  }
                />
                <Route
                  exact
                  path=':cat1/:cat2/:name/:proId'
                  element={
                    <AppSuspense>
                      <ProductDetailPage />
                    </AppSuspense>
                  }
                />
                <Route
                  exact
                  path=':cat1/:name/:proId'
                  element={
                    <AppSuspense>
                      <ProductDetailPage />
                    </AppSuspense>
                  }
                />
              </Route>
              {/* PDP Router */}
              <Route element={<ProtectedRouter />}>
                <Route
                  path='profile'
                  element={
                    <AppSuspense>
                      <ProfilePage />
                    </AppSuspense>
                  }
                >
                  <Route
                    path=''
                    element={
                      <AppSuspense>
                        <MyProfileDescription />
                      </AppSuspense>
                    }
                  />
                  <Route
                    path='wishlist'
                    element={
                      <AppSuspense>
                        <MyWishListPage />
                      </AppSuspense>
                    }
                  />
                  {IsB2BLogged() &&
                  (!quidAccess || (quidAccess && quidAccessStatus === 'Accepted')) ? (
                    <Route
                      path='quid-credit'
                      element={
                        <AppSuspense>
                          <QuidCredit />
                        </AppSuspense>
                      }
                    />
                  ) : null}
                  {IsB2BLogged() && (
                    <Route
                      path='period-discount'
                      element={
                        <AppSuspense>
                          <MilestoneDiscountWrapper viewAll />
                        </AppSuspense>
                      }
                    />
                  )}
                  <Route
                    path='glamourbook-vault'
                    element={
                      <AppSuspense>
                        <GlamourBookVault />
                      </AppSuspense>
                    }
                  />
                  {IsB2BLogged() && (
                    <Route
                      path='GST-input-credit'
                      element={
                        <AppSuspense>
                          <GstInputCredit />
                        </AppSuspense>
                      }
                    />
                  )}
                  <Route
                    path='glamourbook-cash'
                    element={
                      <AppSuspense>
                        <GlamourBookCash />
                      </AppSuspense>
                    }
                  />
                  <Route
                    path='loyalty-point'
                    element={
                      <AppSuspense>
                        <LoyaltyPointPage />
                      </AppSuspense>
                    }
                  />
                  <Route
                    path='referral'
                    element={
                      <AppSuspense>
                        <ReferPage />
                      </AppSuspense>
                    }
                  />
                  <Route
                    path='address'
                    element={
                      <AppSuspense>
                        <Address />
                      </AppSuspense>
                    }
                  />
                  <Route
                    path='order'
                    element={
                      <ApolloProvider client={client}>
                        <AppSuspense>
                          <OrderPage />
                        </AppSuspense>
                      </ApolloProvider>
                    }
                  />
                  <Route
                    path='order-details/:ordId'
                    element={
                      <ApolloProvider client={client}>
                        <AppSuspense>
                          <OrderDetails />
                        </AppSuspense>
                      </ApolloProvider>
                    }
                  />
                  <Route
                    path='notification'
                    element={
                      <AppSuspense>
                        <ManageNotifPage />
                      </AppSuspense>
                    }
                  />
                </Route>
              </Route>
              <Route
                path='loyalty-point'
                element={
                  <AppSuspense>
                    <LoyaltyPointPage />
                  </AppSuspense>
                }
              />
              <Route
                path='community-details/:identifier'
                element={
                  <ApolloProvider client={client}>
                    <AppSuspense>
                      <CommunityDetailsPage />
                    </AppSuspense>
                  </ApolloProvider>
                }
              />
              <Route
                path='order-confirmed/:ordId'
                element={
                  <AppSuspense>
                    <OrderConfirm />
                  </AppSuspense>
                }
              />
              <Route
                path='order-failure'
                element={
                  <AppSuspense>
                    <OrderFailure />
                  </AppSuspense>
                }
              />
              <Route
                path='brands'
                element={
                  <AppSuspense>
                    <BrandLandingPage />
                  </AppSuspense>
                }
              />
              <Route
                path='brand-store'
                element={
                  <AppSuspense>
                    <BrandStorePage />
                  </AppSuspense>
                }
              >
                <Route
                  exact
                  path=':cat1/:cat2/'
                  element={
                    <AppSuspense>
                      <BrandStorePage />
                    </AppSuspense>
                  }
                />
              </Route>
              <Route
                path='page/:pageId'
                element={
                  <AppSuspense>
                    <CmsPage />
                  </AppSuspense>
                }
              />
              <Route
                path='delete-your-account'
                element={
                  <AppSuspense>
                    <DeleteYourAccount />
                  </AppSuspense>
                }
              />
              <Route
                path='get-app'
                element={
                  <AppSuspense>
                    <GetApp />
                  </AppSuspense>
                }
              />
              <Route path='checkout'>
                <Route
                  path='address'
                  element={
                    <ApolloProvider client={client}>
                      <AppSuspense>
                        <CheckoutAddress />
                      </AppSuspense>
                    </ApolloProvider>
                  }
                />
                <Route
                  path='address/:vpId'
                  element={
                    <ApolloProvider client={client}>
                      <AppSuspense>
                        {cartItems.some((item) => item.__typename === VIRTUAL_CART_ITEM) ||
                        !localStorage.getItem('token') ? (
                          <CheckoutAddress />
                        ) : (
                          <CartAddress />
                        )}
                      </AppSuspense>
                    </ApolloProvider>
                  }
                />
              </Route>
            </Route>
          </>
        )}
      </Routes>
      {/* </SentryRoutes> */}
    </>
  );
}

export default AppRouter;
