import { gql } from '@apollo/client';
import { IsB2BEnable, IsB2BLogged } from 'B2B/Utils/IsB2B';

export const PRODUCT_IN_STOCK_ALERT = gql`
  mutation notifyMeMutation($sku: String!) {
    addProductStockAlert(input: { product_sku: $sku }) {
      message
      success
    }
  }
`;

export const STATIC_CONTENT = gql`
  query getFooterContent($content: String!) {
    getStaticContent(content: $content) {
      value
      label
    }
  }
`;

export const GET_ALL_STORE_CONFIG = gql`
  query getAllStoreConfig {
    storeConfig {
      id
      general_store_information_name
      general_store_information_phone
      general_store_information_hours
      general_store_information_street1
      general_store_information_street2
      general_store_information_city
      general_store_information_postcode
      general_store_information_country_id
      general_store_information_region_id
      base_currency_code
      store_name
      copyright
      coupen_display_text
      free_shipping_subtotal
      amrewards_general_enabled
      amrewards_points_minimum_reward
      amrewards_points_rate
      free_shipping_enable
      razorpay_max_amount
      cash_on_delivery_max_amount
      swatch_data_base_url
      sitemap_base_url
      base_media_url
      disallowed_characters
      b2b_popup
      auto_login_popup
      gb_reward
      wallet_faq
      anchor_id
      no_restriction
      wallet_consumption_percentage
      max_wallet_consumption_amount
      ${
        IsB2BEnable()
          ? `
        b2b_company
        magento_customer_group
        b2b_enetity_types
        quid_pay_term
        `
          : ``
      }
    }
  }
`;

export const GET_TIMER_CONFIG = gql`
  query getTimerConfig {
    storeConfig {
      launch_date
      server_time
    }
  }
`;

export const GET_ALL_CAT = gql`
  query getProductsByAttributeType {
    getProductsByAttributeType(attribute_type: "best_seller", category_id: 3) {
      product_id
      product_name
      category_id
      sku
      created_at
      updated_at
      category_name
      product_price
      short_description
      image
      thumbnail
      small_image
    }
  }
`;
export const CATEGORY_LIST = gql`
  query getHeader($label: String) {
    category {
      children {
        id
        uid
        level
        name
        url_path
        url_key
        display_in_top_menu
        include_in_menu
        children {
          id
          uid
          level
          name
          url_path
          url_key
          display_in_top_menu
          include_in_menu
          image
          category_image_2
          children {
            id
            uid
            level
            name
            url_path
            url_key
            display_in_top_menu
            include_in_menu
          }
        }
      }
    }
    getBrandsList(filter_label: $label) {
      is_monetized
      entity_id
      url_key
      title
      is_popular
      is_exclusive
      is_justin
      is_featured
      image
    }
  }
`;

// CART
export const OFFER_LABEL = gql`
  {
    storeConfig {
      coupen_display_text
    }
  }
`;

// ======================================= Brand Landing Page Start ==========================================

// BRAND LIST
export const BRAND_LIST = gql`
  query ($label: String!) {
    getBrandsList(filter_label: $label) {
      is_monetized
      entity_id
      url_key
      title
      status
      image
      description
      stores
      is_popular
      is_exclusive
      is_justin
      is_featured
    }
  }
`;

export const BRAND_LIST_BY_CATEGORY = gql`
  query ($link_category: [String!]) {
    getBrandsList(link_category: $link_category) {
      is_monetized
      entity_id
      url_key
      title
      status
      image
      description
      stores
      is_popular
      is_exclusive
      is_justin
      is_featured
    }
  }
`;

export const BRAND_LIST_MONETIZE = gql`
  query ($monetize: String!) {
    getBrandsList(is_monetized: $monetize) {
      is_monetized
      entity_id
      url_key
      title
      status
      image
      description
      stores
      is_popular
      is_exclusive
      is_justin
      is_featured
    }
  }
`;

export const SEARCH_BRAND_LIST = gql`
  query getSearchBrands {
    getBrandsList {
      is_monetized
      entity_id
      url_key
      title
    }
  }
`;

export const BRAND_LANDING = gql`
  query BrandLanding {
    BannerSlider(page_type: "brand_page") {
      banners {
        alt_text
        is_enabled
        link
        resource_path
        resource_type
        slider_id
        sort_order
        title
      }
      slider_type
      display_type
      is_enabled
      is_show_title
      title
      additional_information
      discover
      identifier
    }
  }
`;
// BRAND LANDING PAGE FEATURED
export const BRAND_FEATURE = gql`
  query {
    BannerSlider(page_type: "brand_page") {
      banners {
        alt_text
        is_enabled
        link
        resource_path
        video_type
        resource_type
        slider_id
        sort_order
        title
        resource_path_poster
      }
      is_enabled
      is_show_title
      title
      product_ids
      identifier
      slider_type
    }
  }
`;

// BRAND STORE
export const BRAND_STORE = gql`
  query {
    getBrandsList(entity_id: 1) {
      entity_id
      title
      status
      image
      description
      stores
      is_popular
      is_exclusive
      is_justin
      is_featured
      url_key
      meta_key
      meta_description
    }
  }
`;

// ======================================= Brand Landing Page End ==========================================

// CATEGORY CAROUSEL
export const CATEGORY_CAROUSEL = gql`
  query {
    BannerSlider(sliderId: 6) {
      additional_information
      banners {
        alt_text
        is_enabled
        link
        resource_path
        resource_type
        slider_id
        sort_order
        title
      }
      discover
      is_enabled
      is_show_title
      link
      product_ids
      slider_id
      title
    }
  }
`;

// User Profile
export const USER_PROFILE = gql`
  query GetUserInformation {
    customer {
      firstname
      lastname
      suffix
      email
      mobilenumber
      date_of_birth
      gender
      referralCode
      emailVerified
      addresses {
        id
        firstname
        lastname
        street
        city
        region {
          region_code
          region
        }
        postcode
        country_code
        telephone
      }
    }
  }
`;

// CART COUPONS
export const CART_COUPONS = (cartId) => gql`
{
  getCoupons(cart_id: "${cartId}") {
    coupon
    description
    to_date
  }
}
`;
export const MUTATE_EMPTY_CART = gql`
  mutation {
    createEmptyCart
  }
`;
export const MUTATE_ADD_CART_PRODUCT = (cartId) => gql`
  mutation {
  addProductsToCart(
    cartId: "${cartId}"
      cartItems: [{ quantity: 4, sku: "Simple-bag" }]
  ) {
      cart {
        items {
          product {
          name
          sku
        }
        quantity
      }
    }
      user_errors {
      code
      message
    }
  }
}
`;
export const OFFER_TEXT = gql`
  query {
    storeConfig {
      coupen_display_text
    }
  }
`;
export const CHECK_PINCODE = (pin) => gql`
{
  zipcode(code: "${pin}") {
    status
    country
    city
  }
}
`;
export const LOYOLTY_POINTS = gql`
  {
    storeConfig {
      amrewards_general_enabled
      amrewards_points_minimum_reward
      amrewards_points_rate
    }
  }
`;

export const ORDER_DETAIL_DATA = gql`
  query {
    rewards {
      balance
    }
    customer {
      firstname
      lastname
      email

      orders {
        total_count
        items {
          number
          id
          invoices {
            id
            items {
              id
              product_name
              product_sku
              quantity_invoiced
            }
          }
          payment_methods {
            name
            type
          }
          shipping_method
          shipments {
            tracking {
              title
              number
              carrier
            }
            id
            number
            items {
              product_sale_price {
                currency
                value
              }
            }
          }
          items {
            id
            brand_name
            image_url
            order_status_cancel

            product_name
            product_url_key
            product_type
            discounts {
              amount {
                currency
                value
              }
              label
            }
            product_sale_price {
              currency
              value
            }
            product_original_price {
              currency
              value
            }
            selected_options {
              label
              value
              value_label
              swatch_data {
                type
                value
              }
            }
          }

          id
          items {
            quantity_ordered
            product_name
            product_type
            status
            selected_options {
              label
              value
            }
          }
          number
          order_date
          shipping_address {
            city
            company
            firstname
            lastname
            middlename
            postcode
            region
            country_code
            region_id
            street
            telephone
          }
          status
          total {
            total_shipping {
              currency
              value
            }
            grand_total {
              currency
              value
            }
            subtotal {
              currency
              value
            }
            base_grand_total {
              currency
              value
            }
            discounts {
              amount {
                currency
                value
              }
              label
            }
          }
        }
      }
    }
  }
`;
// My Account Coupon
export const MYACCOUNT_COUPON = gql`
  query getAllCoupons($cartID: String!) {
    getCoupons(cart_id: $cartID) {
      action
      coupon
      is_mobile_specific
      description
      discount_amount
      from_date
      is_active
      name
      product_ids
      times_used
      to_date
      value
    }
  }
`;

// MYORDER - MY LOYALTY POINT FAQ
export const MYLOYALTYPOINTFAQ = gql`
  query {
    getEasyRewardsStatistic {
      required_balance
      tire
      balance
      expired
      redeemed
      rewarded
    }
  }
`;

export const PROMOTIONAL_PROFILE_DATA = gql`
  query promotionalWallet($curr: Int!, $type: CreditTypeEnum!) {
    promotionalWallet {
      balance_history(pageSize: 10, currentPage: $curr, credit_type: $type) {
        items {
          action
          actual_balance {
            currency
            value
          }
          balance_change {
            currency
            value
          }
          date_time_changed
          action_title
          expire_at
          order_id
        }
        page_info {
          current_page
          page_size
          total_pages
        }
        total_count
      }
      current_balance {
        currency
        value
      }
      enabled
    }
  }
`;

export const WALLET_PROFILE_DATA = gql`
  query gbWallet($curr: Int!, $type: CreditTypeEnum!) {
    gbWallet {
      balance_history(pageSize: 10, currentPage: $curr, credit_type: $type) {
        items {
          action
          actual_balance {
            currency
            value
          }
          balance_change {
            currency
            value
          }
          date_time_changed
          action_title
          expire_at
          order_id
        }
        page_info {
          current_page
          page_size
          total_pages
        }
        total_count
      }
      current_balance {
        currency
        value
      }
      enabled
    }
  }
`;

export const ADD_GB_WALLET = gql`
  mutation addToGbWallet($amount: Float!) {
    addToGbWallet(amount: $amount) {
      response
      cart {
        email
        id
        is_virtual
        available_payment_methods {
          code
          is_deferred
          title
        }
        selected_payment_method {
          code
          purchase_order_number
          title
        }
        billing_address {
          city
          company
          country {
            code
            label
          }
          custom_attributes {
            code
          }
          customer_address_id
          fax
          firstname
          lastname
          middlename
          postcode
          prefix
          region {
            code
            label
            region_id
          }
          street
          suffix
          telephone
          uid
          vat_id
        }
        items {
          prices {
            price {
              currency
              value
            }
          }
          product {
            name
            sku
          }
          quantity
          uid
        }
        prices {
          grand_total {
            currency
            value
          }
        }
        total_quantity
        zipcode_check
      }
    }
  }
`;

// MYORDER - MY ORDER DATA
export const MY_ORDER_DATA = gql`
  query {
    rewards {
      balance
    }
    customer {
      firstname
      lastname
      email

      orders {
        total_count

        items {
          number
          order_date
          number
          id
          invoices {
            id
            items {
              id
              product_name
              product_sku
              quantity_invoiced
            }
          }
          payment_methods {
            name
            type
          }
          shipping_method
          shipments {
            tracking {
              title
              number
              carrier
            }
            id
            number
            items {
              product_sale_price {
                currency
                value
              }
            }
          }
          items {
            id
            brand_name
            image_url
            order_status_cancel

            product_name
            product_url_key
            product_type
            discounts {
              amount {
                currency
                value
              }
              label
            }
            product_sale_price {
              currency
              value
            }
            selected_options {
              label
              value
              value_label
              swatch_data {
                type
                value
              }
            }
          }

          id
          items {
            quantity_ordered
            product_name
            product_type
            status
            selected_options {
              label
              value
            }
          }
          number
          order_date
          shipping_address {
            city
            company
            firstname
            lastname
            middlename
            postcode
            region
            country_code
            region_id
            street
            telephone
          }
          status
          total {
            total_shipping {
              currency
              value
            }
            grand_total {
              currency
              value
            }
            subtotal {
              currency
              value
            }
            base_grand_total {
              currency
              value
            }
            discounts {
              amount {
                currency
                value
              }
              label
            }
          }
        }
      }
    }
  }
`;

export const SET_BILLING_ADDRESS = gql`
  mutation ($cartId: String!, $billing_address: BillingAddressInput!) {
    setBillingAddressOnCart(input: { cart_id: $cartId, billing_address: $billing_address }) {
      cart {
        shipping_addresses {
          firstname
          lastname
          company
          street
          city
          region {
            code
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
        }
      }
    }
  }
`;

export const SET_SHIPPING_ADDRESS = gql`
  mutation ($cartID: String!, $address: ShippingAddressInput) {
    setShippingAddressesOnCart(input: { cart_id: $cartID, shipping_addresses: [$address] }) {
      cart {
        shipping_addresses {
          firstname
          lastname
          company
          street
          city
          region {
            code
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
        }
      }
    }
  }
`;

export const SET_SHIPPING_METHOD = gql`
  mutation ($cartID: String!, $method: ShippingMethodInput!) {
    setShippingMethodsOnCart(input: { cart_id: $cartID, shipping_methods: [$method] }) {
      cart {
        shipping_addresses {
          selected_shipping_method {
            carrier_code
            carrier_title
            method_code
            method_title
            amount {
              value
              currency
            }
          }
        }
      }
    }
  }
`;

export const MINIMUM_ORDER_AMOUNT = gql`
  query {
    storeConfig {
      free_shipping_subtotal
    }
  }
`;

export const SET_PAYMENT_METHOD = gql`
  mutation ($cartID: String!, $code: String!) {
    setPaymentMethodOnCart(input: { cart_id: $cartID, payment_method: { code: $code } }) {
      cart {
        selected_payment_method {
          code
          title
        }
        ${IsB2BLogged() ? 'reserve_order_id' : ''}
        cashback_percentage
      }
    }
  }
`;

export const SEARCH_QUERY = gql`
  {
    productSearch(phrase: "face", page_size: 500) {
      total_count
      items {
        product {
          sku
        }
      }
      suggestions
    }
  }
`;

export const FOOTER_CATEGORY = gql`
  {
    categoryList(filters: { popular_search: { eq: 1 } }) {
      name
      uid
      name
      popular_search
      path
      url_path
      url_key
    }
  }
`;

export const CAT_DATA = gql`
  query footerPopularBrands {
    categoryList(filters: { popular_search: { eq: 1 } }) {
      name
      uid
      name
      popular_search
      path
      url_path
      url_key
    }
  }
`;

export const CMS_CONTENT = gql`
  query cmsPage($label: String!) {
    cmsPage(identifier: $label) {
      title
      url_key
      content_heading
      content
      page_layout
      meta_title
      meta_keywords
      meta_description
    }
  }
`;

export const FOOTER_SEO_CONTENT = gql`
  query getSeoFooterContent(
    $pageType: String!
    $categoryId: Int
    $brandId: Int
    $identifier: String
    $businessType: String!
  ) {
    seoFooterContent(
      pageType: $pageType
      categoryId: $categoryId
      brandId: $brandId
      pageIdentifier: $identifier
      business_type_attribute: $businessType
    ) {
      brand_id
      category_id
      is_active
      page_type
      seo_footer_content
      seo_footer_short_content
      seofootercontent_id
    }
  }
`;

export const ACADEMY_SLIDERS = gql`
  query BannerSlider($sliderIds: [Int]!, $label: String!, $type: String!) {
    BannerSlider(sliderIds: $sliderIds, page_type: $label, type: $type) {
      banners {
        banner_id
        alt_text
        is_enabled
        link
        resource_path
        resource_path_mobile
        resource_path_poster
        resource_type
        slider_id
        sort_order
        title
        video_type
        additional_information
      }
      is_enabled
      faq_banner
      is_show_title
      title
      additional_information
      discover
      sort_order
      product_ids
      slider_type
      display_type
      slider_id
      identifier
      page_type
    }
  }
`;

export const COMMUNITY_DETAILS = gql`
  query ($pageType: String!, $type: String!, $identifier: String!) {
    BannerSlider(page_type: $pageType, type: $type, page_identifier: $identifier) {
      banners {
        banner_id
        alt_text
        is_enabled
        link
        resource_path
        resource_path_mobile
        resource_path_poster
        resource_type
        slider_id
        sort_order
        title
        video_type
        additional_information
      }
      is_enabled
      faq_banner
      is_show_title
      title
      additional_information
      discover
      sort_order
      product_ids
      slider_type
      display_type
      slider_id
      identifier
      page_type
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query refactored(
    $curentPage: Int!
    $filter: ProductAttributeFilterInput
    $pageSize: Int
    $sort: ProductAttributeSortInput
  ) {
    products(filter: $filter, currentPage: $curentPage, pageSize: $pageSize, sort: $sort) {
      total_count
      items {
        uid
        labels
        name
        sku
        special_price
        best_seller
        free_product_available
        only_x_left_in_stock
        stock_status
        rating_summary
        offer_rules {
          discount
          type
          name
          show_on_product
        }
        brand_name
        thumbnail {
          ...ProductImageFields
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
          }
        }
        ${
          IsB2BLogged
            ? `
          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            quantity
          }`
            : ``
        }
        price_range {
          minimum_price {
            regular_price {
              ...MoneyFields
            }
            discount {
              percent_off
            }
            final_price {
              ...MoneyFields
            }
          }
        }
        __typename
      }
      total_count
      page_info {
        page_size
        current_page
        total_pages
      }
      __typename
    }
  }
  fragment ProductImageFields on ProductImage {
    label
    url
  }
  fragment MoneyFields on Money {
    value
  }
`;
export const CHECK_CUSTOMER_EXISTS = gql`
  query CheckCustomerExists($fieldValue: String!, $type: String!) {
    checkCustomerExists(field_value: $fieldValue, type: $type)
  }
`;
export const CREATE_ACCOUNT_EMAIL_OTP_MUTATION = gql`
  mutation CreateAccountEmailOTP($email: String!, $websiteId: Int!) {
    createAccountEmailOTP(email: $email, websiteId: $websiteId) {
      message
      status
    }
  }
`;
export const CREATE_ACCOUNT_OTP_MUTATION = gql`
  mutation CreateAccountOTP($mobileNumber: String!, $websiteId: Int!) {
    createAccountOTP(mobileNumber: $mobileNumber, websiteId: $websiteId) {
      message
      status
    }
  }
`;
export const LOGIN_OTP_MUTATION = gql`
  mutation LoginOTP($mobileNumber: String!, $websiteId: Int!) {
    loginOTP(mobileNumber: $mobileNumber, websiteId: $websiteId) {
      status
      message
    }
  }
`;
export const GENERATE_CUSTOMER_TOKEN_MUTATION = gql`
  mutation GenerateCustomerToken($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
  }
`;

export const GENERATE_CUSTOMER_TOKEN_MOBILE = gql`
  mutation GenerateCustomerTokenMobile($mobile: String!, $password: String!) {
    generateCustomerTokenMobile(mobile: $mobile, password: $password) {
      token
    }
  }
`;
export const EMAIL_LOGIN_OTP_MUTATION = gql`
  mutation EmailLoginOTP($email: String!, $websiteId: Int!) {
    emailLoginOTP(email: $email, websiteId: $websiteId) {
      status
      message
    }
  }
`;
export const GET_APP_LINK_MUTATION = gql`
  mutation getAppLink($mobile: String!) {
    sendAppLink(number: $mobile) {
      status
    }
  }
`;
export const CREATE_ACCOUNT_EMAIL_OTP_VERIFY_MUTATION = gql`
  query CreateAccountEmailOTPVerify($email: String!, $otp: String!, $websiteId: Int!) {
    createAccountEmailOTPVerify(email: $email, otp: $otp, websiteId: $websiteId) {
      status
      message
    }
  }
`;

export const EMAIL_LOGIN_OTP_VERIFY_MUTATION = gql`
  query EmailLoginOTPVerify($email: String!, $otp: String!, $websiteId: Int!) {
    emailLoginOTPVerify(email: $email, otp: $otp, websiteId: $websiteId) {
      status
      message
      token
    }
  }
`;
export const LOGIN_OTP_VERIFY = gql`
  query LoginOTPVerify($mobileNumber: String!, $otp: String!, $websiteId: Int!) {
    loginOTPVerify(mobileNumber: $mobileNumber, otp: $otp, websiteId: $websiteId) {
      message
      status
      token
    }
  }
`;

export const CREATE_CUSTOMER_ACCOUNT_MUTATION = gql`
  mutation CreateCustomerAccountMutation(
    $input: CustomerInput!
    $mobileNumber: String!
    $otp: String!
    $type: String!
    $websiteId: Int!
    $referralCode: String!
  ) {
    createCustomerAccount(
      input: $input
      mobileNumber: $mobileNumber
      otp: $otp
      type: $type
      websiteId: $websiteId
      referralCode: $referralCode
    ) {
      customer {
        email
        firstname
        lastname
        is_subscribed
        privacy_policy_version
        software_agreement_version
        terms_condition_version
      }
      status
      message
    }
  }
`;

export const ASSIGN_COMPANY_CUSTOMER = gql`
  mutation assignCompanyToCustomer($id: Int!, $fileName: String, $base64_encoded_file: String) {
    assignCompanyToCustomer(
      company_id: $id
      file_name: $fileName
      base64_encoded_file: $base64_encoded_file
    ) {
      message
      success
    }
  }
`;

export const CREATE_PRE_BUZZ_ACCOUNT = gql`
  mutation CreateCustomerAccountMutation(
    $input: CustomerInput!
    $mobileNumber: String!
    $otp: String!
    $type: String!
    $websiteId: Int!
    $referralCode: String!
    $preBuzz: Boolean
    $hair: String!
    $skin: String!
  ) {
    createCustomerAccount(
      input: $input
      mobileNumber: $mobileNumber
      otp: $otp
      type: $type
      websiteId: $websiteId
      referralCode: $referralCode
      prebuzz: $preBuzz
      primary_hair_concern: $hair
      primary_skin_concern: $skin
    ) {
      customer {
        email
        firstname
        lastname
        is_subscribed
        privacy_policy_version
        software_agreement_version
        terms_condition_version
        __typename
      }
      status
      message
      __typename
    }
  }
`;

export const CREATE_ACCOUNT_MUTATION = gql`
  query CreateAccountOTPVerify($mobileNumber: String!, $otp: String!, $websiteId: Int!) {
    createAccountOTPVerify(mobileNumber: $mobileNumber, otp: $otp, websiteId: $websiteId) {
      message
      status
    }
  }
`;
export const GET_FREQUENT_PRODUCTS = gql`
  query GetFrequentProducts($uid: String!) {
    getFrequentProducts(uid: $uid) {
      products {
        sku
        child_sku
      }
    }
  }
`;

export const PRODUCT_QUERY = gql`
  query FrequentProducts($ids: [String]!) {
    products(filter: { sku: { in: $ids } }) {
      aggregations {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
      total_count
      items {
        categories {
          name
          uid
        }
        global_brand
        child_details {
          attribute_uid
          parent_sku
        }
        __typename
        uid
        labels
        name
        sku
        special_price
        best_seller
        free_product_available
        gst_tax
        only_x_left_in_stock
        stock_status
        rating_summary
        offer_rules {
          discount
          type
          name
          show_on_product
        }
        brand_label
        image {
          label
          sizes {
            image_id
            url
            width
            height
          }
        }
        media_gallery {
          url
          label
          ... on ProductImage {
            label
            role
            sizes {
              image_id
              url
              width
              height
            }
          }
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
            }
          }
          __typename
        }
        thumbnail {
          sizes {
            image_id
            url
            width
            height
          }
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            label
            attribute_code
            values {
              label
              value_index
              swatch_data {
                value
                __typename
              }
            }
          }
          variants {
            product {
              brand_label
              name
              sku
              uid
              free_product_available
              offer_rules {
                discount
                type
                name
                show_on_product
              }
              only_x_left_in_stock
              stock_status
              special_price
              ${
                IsB2BLogged
                  ? `
                price_tiers {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  quantity
                }`
                  : ``
              }
              price_range {
                minimum_price {
                  discount {
                    percent_off
                    __typename
                  }
                  regular_price {
                    value
                    currency
                    __typename
                  }
                  final_price {
                    value
                    currency
                    __typename
                  }
                  __typename
                }
                __typename
              }
              image {
                label
                url
                __typename
              }
              small_image {
                label
                url
                __typename
              }
              media_gallery {
                url
                label
                ... on ProductImage {
                  label
                  role
                  sizes {
                    image_id
                    url
                    width
                    height
                  }
                }
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    __typename
                  }
                  __typename
                }
                __typename
              }
              swatch_image
              __typename
            }
            attributes {
              uid
              label
              code
              value_index
              __typename
            }
            __typename
          }
        }
        ${
          IsB2BLogged()
            ? `
          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            quantity
          }`
            : ``
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
            }
            regular_price {
              value
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
            }
            regular_price {
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_VERSION = gql`
  query {
    getVersion(limit: 1) {
      android_cur_version
      android_min_version
      ios_cur_version
      ios_min_version
      privacy_policy_url
      privacy_policy_version
      software_agreement_url
      software_agreement_version
      status
      terms_condition_url
      terms_condition_version
      version_id
    }
  }
`;

export const GET_CUSTOMER_GENDER = gql`
  {
    customAttributeMetadata(attributes: [{ attribute_code: "gender", entity_type: "customer" }]) {
      items {
        attribute_options {
          value
          label
        }
      }
    }
  }
`;

export const GET_CUSTOMER_LABEL = gql`
  {
    customAttributeMetadata(
      attributes: [{ attribute_code: "labels", entity_type: "catalog_product" }]
    ) {
      items {
        attribute_options {
          value
          label
        }
      }
    }
  }
`;

export const DELETE_CUSTOMER_ACCOUNT = gql`
  mutation {
    deleteCustomer
  }
`;

export const GET_BANNER_SLIDER_PLP = gql`
  query BannerSlider($category_uid: String!, $page_type: String!) {
    BannerSlider(category_uid: $category_uid, page_type: $page_type) {
      banners {
        alt_text
        is_enabled
        link
        resource_path
        resource_type
        resource_path_mobile
        resource_path_poster
        video_type
        slider_id
        sort_order
        title
        target_type
        resource_path
        banner_position
      }
      is_enabled
      is_show_title
      title
      additional_information
      discover
      slider_type
      page_type
      target_type
      product_ids
      category_uid
    }
  }
`;

export const VALIDATE_QR = gql`
  mutation ($token: String!) {
    validateQRcode(token: $token) {
      status
      orderId
      name
      email
      phone
      qrlink
      productname
      people {
        name
      }
    }
  }
`;

export const VIEW_COUPON = gql`
  query viewCoupon($code: String!) {
    viewCoupon(couponCode: $code) {
      voucherCode
      voucherDiscount
      expiryDate
      promotionT_C
      action
    }
  }
`;

export const GET_FAQ_CATEGORIES = gql`
  query GetFaqCategories {
    getFaqCategories {
      uid
      name
      image
    }
  }
`;

export const GET_FAQ_SUBTOPICS = gql`
  query GetFaqSubtopics($uid: String!) {
    getFaqCategories(uid: $uid) {
      uid
      name
    }
  }
`;

export const GET_FAQ_QUESTIONS = gql`
  query GetFaqQuestions($uid: String!) {
    getFaqQuestions(uid: $uid) {
      question
      answer
    }
  }
`;

export const GET_RECENT_VIEWED = gql`
  query getRecentViewed {
    getRecentlyViewed {
      skus
    }
  }
`;
