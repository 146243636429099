/* eslint-disable import/no-cycle */
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { CONFIG_PROD, OOS } from 'Utils/Strings';
import { wishlistImgUrl, PLP_WEB_PRODUCT_IMAGE } from 'Utils/filterImageUrl';
import {
  removeFromWishlist,
  moveWishlistToCart,
  toggleRefetchWishlist,
} from 'Features/wishlist/wishlistSlice';
import { X } from 'phosphor-react';
import { notification } from 'Utils/Toast';
import Media from './Media';
import './WishlistDropdownCard.css';

function WishlistDropdownCard({ product, swatch_data_base_url }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let queryStringCuid = null;
  const Cuid = product?.configurable_options?.[0]?.configurable_product_option_value_uid;

  if (Cuid) {
    // Using URLSearchParams to construct the query string
    const queryParams = new URLSearchParams();
    queryParams.append('Cuid', Cuid);
    queryStringCuid = queryParams.toString();
  }

  function getProductImage(product) {
    switch (product.__typename) {
      case 'ConfigurableWishlistItem': {
        if (product.configured_variant !== null) {
          const imageLink = product?.configured_variant?.thumbnail;
          return imageLink;
        }
        const imageLink = wishlistImgUrl(product, 'product', PLP_WEB_PRODUCT_IMAGE, 'thumbnail');
        return imageLink;
      }
      case 'SimpleWishlistItem': {
        const imageLink = wishlistImgUrl(product, 'product', PLP_WEB_PRODUCT_IMAGE, 'thumbnail');
        return imageLink;
      }
      default:
        throw new Error('Unknown product type');
    }
  }

  const ProImg = getProductImage(product);

  function getCurrentPrice(product) {
    switch (product.__typename) {
      case 'ConfigurableWishlistItem': {
        if (product.configured_variant !== null) {
          const currentPrice =
            product?.configured_variant.price_range.minimum_price.final_price?.value || '';
          return currentPrice;
        }
        const currentPrice = product?.product.price_range.minimum_price.final_price?.value || '';
        return currentPrice;
      }
      case 'SimpleWishlistItem': {
        const currentPrice = product?.product.price_range.minimum_price.final_price?.value || '';
        return currentPrice;
      }
      default:
        throw new Error('Unknown product type');
    }
  }

  function getWishlistCurrency(product) {
    return product?.product?.price_range.minimum_price.regular_price.currency;
  }

  const SHOW_BY_DEFAULT = 3;

  // handle remove wishlist
  const handleRemoveWishlist = async (productID) => {
    try {
      await dispatch(removeFromWishlist(productID)).unwrap();
      dispatch(toggleRefetchWishlist(true));
      notification.success('Product removed from wishlist successfully!');
    } catch (error) {
      console.error('Error Removing to wishlist:', error);
    }
  };

  function getOriginalPrice(product) {
    switch (product.__typename) {
      case 'ConfigurableWishlistItem': {
        if (product.configured_variant !== null) {
          const originalPrice =
            product?.configured_variant.price_range.minimum_price.regular_price?.value || '';
          const currentPrice =
            product?.configured_variant.price_range.minimum_price.final_price?.value || '';
          return originalPrice !== currentPrice ? originalPrice : null;
        }
        const originalPrice = product?.product.price_range.minimum_price.regular_price?.value || '';
        const currentPrice = product?.product.price_range.minimum_price.final_price?.value || '';
        return originalPrice !== currentPrice ? originalPrice : null;
      }
      case 'SimpleWishlistItem': {
        const originalPrice = product?.product.price_range.minimum_price.regular_price?.value || '';
        const currentPrice = product?.product.price_range.minimum_price.final_price?.value || '';
        return originalPrice !== currentPrice ? originalPrice : null;
      }
      default:
        throw new Error('Unknown product type');
    }
  }

  function getDiscount(product) {
    switch (product.__typename) {
      case 'ConfigurableWishlistItem': {
        if (product.configured_variant !== null) {
          const discount =
            product?.configured_variant?.price_range?.minimum_price.discount?.amount_off;
          const originalPrice =
            product?.configured_variant.price_range.minimum_price.regular_price?.value || '';
          if (discount !== 0) {
            return Math.round((discount / originalPrice) * 100);
          }
          return null;
        }
        const discount = product?.product?.price_range?.minimum_price?.discount?.percent_off;
        if (discount !== 0) {
          return Math.round(discount);
        }
        return null;
      }
      case 'SimpleWishlistItem': {
        const discount = product?.product?.price_range?.minimum_price?.discount?.percent_off;
        if (discount !== 0) {
          return discount;
        }
        return null;
      }
      default:
        throw new Error('Unknown product type');
    }
  }

  const discount = getDiscount(product);

  function forConfigurableProd(product) {
    if (product?.__typename !== 'ConfigurableWishlistItem') {
      return null;
    }
    const { configurable_options, product: configProduct } = product;
    if (configurable_options.length === 0) {
      const label = configProduct.configurable_options[0]?.label || '';
      if (label === 'Color') {
        const colorOption =
          configProduct.__typename === CONFIG_PROD
            ? configProduct.variants?.map((variant) =>
                configProduct.configurable_options[0]?.values?.find(
                  (configurableValue) =>
                    configurableValue?.value_index === variant?.attributes?.[0].value_index,
                ),
              )
            : [];

        return (
          <div className='wishdrop-color-parent'>
            {colorOption && colorOption.length !== 0 && (
              <div className='wishdrop-color'>
                {/* <p className='wishdrop-variant-selected'>
                  Color:
                </p> */}
                <div className='wishdrop-colors'>
                  {colorOption &&
                    colorOption?.slice(0, SHOW_BY_DEFAULT)?.map((color) => (
                      <div className='plp-infoHolder-ConfigOptions-color '>
                        {/#/.test(color.swatch_data?.value) ? (
                          <div
                            className='plp-infoHolder-ConfigOptions-colorImage wish-plp-clr'
                            style={{ background: color.swatch_data?.value }}
                          />
                        ) : (
                          <>
                            <img
                              className='plp-infoHolder-ConfigOptions-colorImage'
                              src={`${swatch_data_base_url}${color.swatch_data?.value}`}
                              alt=''
                            />
                            <div className={color.disable && 'disabled'} />
                          </>
                        )}
                      </div>
                    ))}
                  {colorOption?.length > 1 ? (
                    <span className='moreColors-wishlist'>
                      {`${colorOption.length} Shades Available`}
                    </span>
                  ) : (
                    <span className='moreColors-wishlist'>
                      {`${colorOption.length} Shade Available`}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      }
      if (label === 'Size') {
        const sizeOption =
          configProduct.__typename === CONFIG_PROD
            ? configProduct.variants?.map((variant) =>
                configProduct.configurable_options[0]?.values?.find(
                  (configurableValue) =>
                    configurableValue?.value_index === variant?.attributes?.[0].value_index,
                ),
              )
            : [];

        const sizeOptionChild = sizeOption.map((size) => size.label);

        const sizeOptionToShow = sizeOptionChild.slice(0, SHOW_BY_DEFAULT).join('/');
        const moreSizesCount = sizeOptionChild.length - SHOW_BY_DEFAULT;

        return (
          <div className='wishdrdwn wishdrop-color-parent'>
            {sizeOptionToShow && sizeOptionToShow !== '' && (
              <div className='plp-infoHolder-ConfigOptions-colorList'>
                <p className='size-products-available variant-selected'>{sizeOptionToShow}</p>{' '}
                {moreSizesCount > 0 && (
                  <span className='moreSizes-wishlist'>{`+${moreSizesCount} More`}</span>
                )}
              </div>
            )}
            {sizeOptionToShow === '' && (
              <div className='color-products-available variant-selected'> </div>
            )}
          </div>
        );
      }
    }
    const selectedVariant = configurable_options[0];

    const data = product.product.configurable_options[0].values.find(
      (item) => item.uid === selectedVariant.configurable_product_option_value_uid,
    );

    return (
      <div className='plp-infoHolder-ConfigOptions-colorList'>
        {/* <p className='variant-selected'>{selectedVariant.option_label}:</p> */}
        {selectedVariant.option_label === 'Color' ? (
          data && (
            <div className='plp-infoHolder-ConfigOptions-color'>
              {/#/.test(data.swatch_data?.value) ? (
                <>
                  <div
                    className='plp-infoHolder-ConfigOptions-colorImage wish-plp-clr'
                    style={{ background: data.swatch_data?.value }}
                  />
                  <span className='singshade'> {selectedVariant.value_label}</span>
                </>
              ) : (
                <>
                  <img
                    className='plp-infoHolder-ConfigOptions-colorImage'
                    src={`${swatch_data_base_url}${data.swatch_data?.value}`}
                    alt=''
                  />
                  <span className='singshade'> {selectedVariant.value_label}</span>
                  {/* <div className={data.disable && 'disabled'} /> */}
                </>
              )}
            </div>
          )
        ) : (
          <div className='size-products-available variant-selected'>
            {selectedVariant.value_label}
          </div>
        )}
      </div>
    );
  }

  const handleAddToBag = async (productID) => {
    try {
      await dispatch(moveWishlistToCart(productID)).unwrap();
      dispatch(toggleRefetchWishlist(true));
      notification.success('Item moved to cart successfully');
    } catch (error) {
      notification.error(error);
    }
  };
  return (
    <div className='dropdownWishlistProd'>
      <div
        className='mini-wishdrop-prod'
        role='button'
        tabIndex={0}
        onClick={() => {
          navigate(
            `/${product?.product?.brand_label
              ?.replace(/[^a-zA-Z0-9]/g, '-')
              .replace(/-{2,}/g, '-')
              .toLowerCase()}/${product?.product?.sku}
              ${queryStringCuid ? `?${queryStringCuid}` : ''}`,
          );
        }}
      >
        <div className='imgWrapper'>
          <Media src={ProImg.url} alt={ProImg.label} />
        </div>
        <div className='wishdrop-details'>
          <h4 className='wishdrop-brand'>{product.product.brand_label || 'brand'}</h4>
          <p className='wishdrop-brandname'>
            {product?.configured_variant?.name || product?.product?.name}
          </p>
          <div className='wishdrop-price'>
            <div className='d-flex'>
              <span className='wishdrop-fp'>
                {new Intl.NumberFormat('en-us', {
                  style: 'currency',
                  currency: getWishlistCurrency(product) || 'INR',
                  maximumFractionDigits: 0,
                })
                  .format(getCurrentPrice(product))
                  .replace(/^(\D+)/, '$1 ')}
              </span>
            </div>
            {discount && (
              <>
                <div className='d-flex strike-dis'>
                  <span className='wishdrop-rp'>
                    {new Intl.NumberFormat('en-us', {
                      style: 'currency',
                      currency: getWishlistCurrency(product) || 'INR',
                      maximumFractionDigits: 0,
                    })
                      .format(getOriginalPrice(product))
                      .replace(/^(\D+)/, '$1 ')}
                  </span>
                </div>
                <div className='d-flex'>
                  <span className='dis'>{`${Math.round(discount)}% OFF`}</span>
                </div>
              </>
            )}
          </div>
          <div className='d-flex justify-content-between'>
            <div className='d-flex justify-content-start'>{forConfigurableProd(product)}</div>
            <div className='d-flex justify-content-end'>
              {((product.product.stock_status !== OOS &&
                product.product?.variants?.length === undefined) ||
                (product?.configurable_options?.length === 1 &&
                  product?.configured_variant?.stock_status !== OOS)) && (
                <div className='bottomWrapper'>
                  <button
                    type='button'
                    tabIndex={0}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAddToBag(product.id);
                    }}
                  >
                    Add To Bag
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            role='button'
            tabIndex={0}
            className='wishdrop-close-icon'
            onClick={(event) => {
              event.stopPropagation();
              handleRemoveWishlist(product.id);
            }}
          >
            <X size={15} color='#000' weight='thin' className='' />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  wishlistID: state.wishlist.wishlistID,
  swatch_data_base_url: state.storeConfig.swatch_data_base_url,
});

export default connect(mapStateToProps)(WishlistDropdownCard);
