import { tinyUrl } from 'Hooks/Global/useTinyUrl';
import { pushToDataLayer } from 'Utils/pushToDataLayer';

export const productDetailGtag = async (eventName, prod, parentProduc, getShortURL = () => {}) => {
  window.dataLayer.push({ ecommerce: null });
  if (Object.keys(prod || {}).length) {
    const dataUrl = await tinyUrl(prod?.image?.url);
    pushToDataLayer({
      event: eventName,
      ecommerce: {
        prid: prod?.sku,
        image: dataUrl?.tinyUrl,
        prqt: 1,
        product_name: prod?.name,
        category: parentProduc?.categories?.[0]?.name || 'none',
        product_url: await getShortURL(),
        brand: prod?.brand_label,
        discounted_price: prod?.price_range?.minimum_price?.final_price?.value,
        selling_price: prod?.price_range?.minimum_price?.regular_price?.value,
        stock_availability: prod?.stock_status === 'IN_STOCK' ? 1 : 0,
      },
    });
  }
};

export const productCardGtag = async (
  eventName,
  product,
  category = '',
  product_url_path = () => {},
) => {
  window.dataLayer.push({ ecommerce: null });
  if (Object.keys(product || {}).length) {
    const dataUrl = await tinyUrl(product?.thumbnail?.url);
    pushToDataLayer({
      event: eventName,
      ecommerce: {
        prid: product?.sku,
        prqt: 1,
        image: dataUrl?.tinyUrl,
        product_name: product?.name,
        category,
        product_url: `https://glamourbook.com${product_url_path()}`,
        brand: product?.brand_label,
        selling_price: product?.price_range?.minimum_price?.regular_price?.value,
        discounted_price: product?.price_range?.minimum_price?.final_price?.value,
        stock_availability: product?.stock_status === 'IN_STOCK' ? 1 : 0 || 0,
      },
    });
  }
};

export const categoryGtag = (name) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: 'CategoryViewed',
    ecommerce: {
      category_name: name,
    },
  });
};

export const searchGtag = (name) => {
  window.dataLayer.push({ ecommerce: null });
  if (name) {
    pushToDataLayer({
      event: 'ProductSerched',
      ecommerce: {
        keyword: name,
      },
    });
  }
};

export const paymentFailureGtag = (userEmail, userMobileNumber, paymentMethod) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: 'paymentFailed',
    ecommerce: {
      email: userEmail,
      userMobileNumber,
      paymentMethod,
    },
  });
};

export const brandVisitGtag = (userEmail, brandTitle) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: 'brandVisit',
    ecommerce: {
      email: userEmail,
      brand: brandTitle,
    },
  });
};

export const bannerNavigationGtag = (eventName, banner_id, banner_name, page_type, brandName) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: eventName,
    ecommerce: {
      banner_id,
      banner_name,
      page_name: page_type,
      brand: brandName,
    },
  });
};

export const bestSellerGtag = (mobile, pos, title, brand, product) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: 'bestseller_click',
    ecommerce: {
      mobile: mobile ?? '',
      bestseller_position: pos,
      slider_name: title ?? 'slider',
      brand_name: brand ?? '',
      product_name: product ?? '',
    },
  });
};

export const appLinkGtag = (mobile, page, pos, os) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: 'app_link_click',
    ecommerce: {
      mobile: mobile ?? '',
      page_name: page,
      banner: pos,
      os_type: os,
    },
  });
};

export const blogClickGtag = (mobile, title, screen) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: 'blog_page_click',
    ecommerce: {
      mobile: mobile ?? '',
      blog_title: title,
      screen_name: screen,
    },
  });
};

export const discoverMoreGtag = (mobile, banner) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: 'Discover more',
    ecommerce: {
      mobile: mobile ?? '',
      slider_name: banner?.title,
      screen_name: banner.page_type,
    },
  });
};

export const filterGtag = (filter, filter_name, screen, mobile, cat) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: 'Filters',
    ecommerce: {
      filter_value: parseInt(filter?.value),
      filter_count: filter?.count,
      screen_name: screen,
      mobile: mobile ?? '',
      filter_name,
      category_name: cat,
    },
  });
};

export const sortGtag = (screen, mobile, sort, cat) => {
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer({
    event: 'Sort',
    ecommerce: {
      screen_name: screen,
      mobile: mobile ?? '',
      sort_option: sort,
      category_name: cat,
    },
  });
};
