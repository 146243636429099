/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client as postClient } from 'Routes/index';
import { client } from 'Gql';
import { GET_ALL_STORE_CONFIG, GET_TIMER_CONFIG } from 'Gql/query';
import { CUSTOMER_TYPE_CHECK } from 'B2B/Gql';

const initialState = {
  loading: false,
  id: '',
  general_store_information_name: '',
  general_store_information_phone: '',
  general_store_information_hours: '',
  general_store_information_street1: '',
  general_store_information_street2: '',
  general_store_information_city: '',
  general_store_information_postcode: '',
  base_currency_code: '',
  store_name: '',
  copyright: '',
  coupen_display_text: '',
  free_shipping_subtotal: '',
  amrewards_general_enabled: '',
  amrewards_points_minimum_reward: '',
  amrewards_points_rate: '',
  free_shipping_enable: '',
  razorpay_max_amount: '',
  cash_on_delivery_max_amount: '',
  swatch_data_base_url: '',
  sitemap_base_url: '',
  disallowed: '',
  launch_date: '',
  server_time: '',
  b2b_popup: '',
  auto_login_popup: '',
  wallet_faq: '',
};

export const getAllStoreConfig = createAsyncThunk(
  'storeConfig/getAllStoreConfig',
  async (_, { rejectWithValue }) => {
    try {
      const storeData = await client.query({
        query: GET_ALL_STORE_CONFIG,
      });
      return storeData.data;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const getTimerConfig = createAsyncThunk(
  'storeConfig/getTimerConfig',
  async (_, { rejectWithValue }) => {
    try {
      const storeData = await postClient.query({
        query: GET_TIMER_CONFIG,
      });
      return storeData.data;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const getCustomerTypeCheck = createAsyncThunk(
  'storeConfig/getCustomerTypeCheck',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: CUSTOMER_TYPE_CHECK,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const StoreConfigSlice = createSlice({
  initialState,
  name: 'StoreConfig',
  extraReducers: (builder) => {
    builder
      .addCase(getAllStoreConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStoreConfig.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllStoreConfig.fulfilled, (state, { payload }) => {
        if (payload) {
          const { __typename, ...other } = payload.storeConfig;
          state.loading = false;
          state.id = other.id;
          state.general_store_information_name = other.general_store_information_name;
          state.wallet_faq = other.wallet_faq;
          state.general_store_information_phone = other.general_store_information_phone;
          state.general_store_information_hours = other.general_store_information_hours;
          state.general_store_information_street1 = other.general_store_information_street1;
          state.general_store_information_street2 = other.general_store_information_street2;
          state.general_store_information_city = other.general_store_information_city;
          state.general_store_information_postcode = other.general_store_information_postcode;
          state.general_store_information_country_id = other.general_store_information_country_id;
          state.general_store_information_region_id = other.general_store_information_region_id;
          state.base_currency_code = other.base_currency_code;
          state.store_name = other.store_name;
          state.copyright = other.copyright;
          state.coupen_display_text = other.coupen_display_text;
          state.free_shipping_subtotal = other.free_shipping_subtotal;
          state.amrewards_general_enabled = other.amrewards_general_enabled;
          state.amrewards_points_minimum_reward = other.amrewards_points_minimum_reward;
          state.amrewards_points_rate = other.amrewards_points_rate;
          state.free_shipping_enable = other.free_shipping_enable;
          state.razorpay_max_amount = other.razorpay_max_amount;
          state.cash_on_delivery_max_amount = other.cash_on_delivery_max_amount;
          state.swatch_data_base_url = other.swatch_data_base_url;
          state.sitemap_base_url = other.sitemap_base_url;
          state.base_media_url = other.base_media_url;
          state.disallowed = other.disallowed_characters;
          state.isB2BAllowed = other.b2b_company;
          state.magento_customer_group = other.magento_customer_group;
          state.b2bEnetityTypes = other.b2b_enetity_types;
          state.b2b_popup = other.b2b_popup;
          state.auto_login_popup = other.auto_login_popup;
          state.anchorId = other.anchor_id;
          state.quidPayTerm = other.quid_pay_term;
          state.noRestriction = other.no_restriction;
          state.walletConsumptionPercentage = other.wallet_consumption_percentage;
          state.maxWalletConsumptionAmount = other.max_wallet_consumption_amount;
        }
      })
      .addCase(getTimerConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTimerConfig.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTimerConfig.fulfilled, (state, { payload }) => {
        if (payload) {
          const { __typename, ...other } = payload.storeConfig;
          state.launch_date = other.launch_date;
          state.server_time = other.server_time;
        }
      })
      .addCase(getCustomerTypeCheck.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerTypeCheck.rejected, (state) => {
        state.loading = false;
        localStorage.removeItem('isB2B');
      })
      .addCase(getCustomerTypeCheck.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.data.customerTypeCheck.type === 'b2b') {
          localStorage.setItem('isB2B', true);
        } else {
          localStorage.removeItem('isB2B');
        }
      });
  },
});

export default StoreConfigSlice.reducer;
