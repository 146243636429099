import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from 'Components/Homepage/Header';
import { useDispatch } from 'react-redux';
import useListenAllWishlistEvents from 'events/wishlistEvent';
import useListenAllCartEvents from 'events/cartEvents';
import { IsB2BLogged, IsB2BRoute } from 'B2B/Utils/IsB2B';
import { getFooterSEOVars } from 'Features/global';
import Footer from './Footer';
import './Layout.css';

/**
 *
 * @param root0
 * @param root0.children
 * @param root0.stickyHeaderShow
 * @param root0.footerShow
 */
function Layout({ footerShow = true }) {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  const [isQRValidatePage, setIsQRValidatePage] = useState(false);
  const [isTicketDownloadPage, setIsTicketDownloadPage] = useState(false);
  const hideFooter = ['/qrvalidate', '/ticketdownload', '/voucherdetails'].some(
    (path) => pathname.includes(path) || (IsB2BRoute() && !IsB2BLogged()),
  );

  const footerExceptions = [
    'category',
    'brand-store',
    'brands',
    'offers',
    'gifts',
    'academy',
    'community',
    'product-listing',
    'community-details',
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  useEffect(() => {
    const isQRValidate = pathname.includes('/qrvalidate');
    const isTicketDownload = pathname.includes('ticketdownload');
    const queryParams = new URLSearchParams(search);
    const token = queryParams.get('TOKEN');
    const isTokenAvailable = token !== null;

    if ((isTicketDownload || isQRValidate) && isTokenAvailable) {
      setIsTicketDownloadPage(true);
      setIsQRValidatePage(true);
    } else {
      setIsQRValidatePage(false);
      setIsTicketDownloadPage(false);
    }
  }, [pathname, search]);

  useEffect(() => {
    const data = sessionStorage.getItem('keep_filter');
    let url = null;

    if (data) {
      url = JSON.parse(data).path;
    }

    if (url !== pathname) {
      sessionStorage.removeItem('keep_filter');
      localStorage.removeItem('level_three');
      sessionStorage.removeItem('ignore_filter');
      sessionStorage.removeItem('currPriceFilter');
    }

    if (!footerExceptions.some((exception) => pathname.includes(exception))) {
      dispatch(getFooterSEOVars({ pageType: 'home_page' }));
    }
  }, [pathname]);

  useEffect(() => {
    const backHandler = () => {
      if (pathname.includes('profile') && window.innerWidth < 768) {
        if (pathname === '/profile/order') {
          setTimeout(() => {
            window.scrollTo({
              top: 770,
              behavior: 'smooth',
            });
            document.documentElement.scrollTo({
              top: 770,
            });
          }, 100);
        } else {
          window.scrollTo({
            top: 770,
          });
          document.documentElement.scrollTo({
            top: 770,
          });
        }
      } else {
        window.scrollTo({
          top: 0,
        });
      }
    };
    window.addEventListener('popstate', backHandler);
    backHandler();
    return window.removeEventListener('popstate', backHandler);
  }, [pathname]);

  useListenAllWishlistEvents();
  useListenAllCartEvents();

  return (
    <>
      {!isQRValidatePage || !isTicketDownloadPage ? <Header /> : ''}
      <div className='main-content' id='main-content'>
        <Outlet />
      </div>
      {footerShow && !hideFooter ? <Footer /> : ''}
    </>
  );
}

export default Layout;
