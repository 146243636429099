/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { setLoading } from 'Features/GlobalLoading/LoadingSlice';
import { getCartDetails } from 'Features/cart/cartSlice';
import { changeTrigger } from 'Features/login';
import wishlistServices from 'Features/wishlist/wishlistServices';
import { notification } from 'Utils/Toast';

const initialState = {
  allWishlist: [],
  wishlist: [],
  wishlistCount: 0,
  wishlistID: '',
  loading: false,
  status: '',
  message: '',
  refetchWishlist: false,
};

export const getWishlistID = createAsyncThunk(
  'wishlist/getWishlistID',
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) return arg;
      return await wishlistServices.getWishlistID();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getWishlist = createAsyncThunk(
  'wishlist/getWishlist',
  async (arg, { rejectWithValue }) => {
    try {
      return await wishlistServices.getAllWishlistOnFirstLoad(arg);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const FetchMoreWishlist = createAsyncThunk(
  'wishlist/FetchMoreWishlist',
  async (arg, { rejectWithValue }) => {
    try {
      return await wishlistServices.getWishlist(arg);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addToWishlist = createAsyncThunk(
  'wishlist/addToWishlist',
  async ({ sku, selectedOptions }, { getState, dispatch, rejectWithValue }) => {
    try {
      if (getState().cart.userFirstName.length === 0) {
        dispatch(changeTrigger());
        return rejectWithValue({ token: false });
      }
      const added = await wishlistServices.addToWishlist({ sku, selectedOptions }, getState());
      return added;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  },
);

export const removeFromWishlist = createAsyncThunk(
  'wishlist/removeFromWishlist',
  async (sku, { getState, rejectWithValue }) => {
    try {
      const removed = wishlistServices.removeFromWishlist(sku, getState());

      return removed;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const moveWishlistToCart = createAsyncThunk(
  'wishlist/moveWishlistToCart',
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const moved = await wishlistServices.moveWishlistToCart(id, getState());
      // await dispatch(getCustomerCartCount());
      await dispatch(getCartDetails());
      dispatch(setLoading(false));
      if (!moved.addWishlistItemsToCart.status) {
        return rejectWithValue(
          moved.addWishlistItemsToCart.add_wishlist_items_to_cart_user_errors[0].message,
        );
      }

      return { data: moved.addWishlistItemsToCart, id };
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  },
);

export const getAllWishlist = createAsyncThunk(
  'wishlist/getAllWishlist',
  async (_, { rejectWithValue }) => {
    try {
      return await wishlistServices.getAllWishlist();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateWishlistVariants = createAsyncThunk(
  'wishlist/updateWishlistVariants',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const updated = await wishlistServices.updateWishlistVariants(data, getState());
      const arg = {
        currentPage: 0,
        pageSize: 0,
      };
      const allWishlist = await wishlistServices.getWishlist(arg);
      dispatch(setLoading(false));
      return { updated, allWishlist };
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  },
);

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    resetWishlist: () => initialState,
    removeWishlist: (state) => {
      state.wishlist = [];
    },
    toggleRefetchWishlist: (state, { payload }) => {
      state.refetchWishlist = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWishlistID.fulfilled, (state, { payload }) => {
        state.wishlistID = payload.id ?? 0;
        state.wishlistCount = payload.items_count;
        state.allWishlist = payload.items_v2.items;
        state.loading = false;
        state.status = 'success';
        state.message = '';
      })
      .addCase(getWishlistID.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(getWishlistID.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getWishlist.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(getWishlist.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getWishlist.fulfilled, (state, { payload }) => {
        state.wishlistCount = payload?.items_count;
        state.wishlist = payload?.items_v2?.items;
        state.loading = false;
        state.status = 'success';
        state.message = '';
        state.refetchWishlist = false;
      })
      .addCase(addToWishlist.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(addToWishlist.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.message = payload;
        if (!payload.token) {
          return null;
        }
        notification.error(
          'Oops! something went wrong. We were not able to add the product to the wishlist. Please try again!',
        );
      })
      .addCase(addToWishlist.fulfilled, (state, { payload }) => {
        state.allWishlist = payload?.payload?.items_v2?.items;
        state.wishlist = payload?.payload?.items_v2?.items;
        state.wishlistCount = payload?.payload?.items_count;
        state.status = 'success';
        state.message = '';
      })
      .addCase(removeFromWishlist.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(removeFromWishlist.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
        notification.error('Product was not removed from wishlist. Please try again!');
      })
      .addCase(removeFromWishlist.fulfilled, (state, { payload: { payload, id } }) => {
        const { items_count } = payload ?? {};
        if (typeof id === 'string') {
          const result = state?.allWishlist.filter((item) => item.id !== id);
          const result2 = state?.wishlist.filter((item) => item.id !== id);
          state.wishlist = result2;
          state.allWishlist = result;
        } else {
          const filteredArray = state?.allWishlist.filter((obj) => !id.includes(obj.id));
          const result2 = state?.wishlist.filter((obj) => !id.includes(obj.id));
          state.wishlist = result2;
          state.allWishlist = filteredArray;
        }
        state.wishlistCount = items_count;
        state.loading = false;
        state.status = 'success';
        state.message = payload;
      })
      .addCase(moveWishlistToCart.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(moveWishlistToCart.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.message = payload;
        notification.error(payload);
      })
      .addCase(moveWishlistToCart.fulfilled, (state, { payload }) => {
        const { id, data } = payload ?? {};
        const result = state.wishlist.filter((item) => item.id !== id);
        state.wishlist = result;
        state.wishlistCount -= data.wishlist.items_count;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(updateWishlistVariants.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateWishlistVariants.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(updateWishlistVariants.fulfilled, (state, { payload }) => {
        const { items_count } = payload.updated ?? {};
        state.wishlistCount = items_count;
        state.wishlist = payload.allWishlist;
        state.allWishlist = payload.allWishlist;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(FetchMoreWishlist.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(FetchMoreWishlist.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(FetchMoreWishlist.fulfilled, (state, { payload }) => {
        state.wishlist.push(...payload);
        state.loading = false;
        state.status = 'success';
      })
      .addCase(getAllWishlist.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(getAllWishlist.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getAllWishlist.fulfilled, (state, { payload }) => {
        state.allWishlist = payload;
        state.loading = false;
        state.status = 'success';
      });
  },
});
export const { resetWishlist, removeWishlist, toggleRefetchWishlist } = wishlistSlice.actions;
export const getLoadingWishlist = (state) => state.wishlist.loading;
export const Wishlist = (state) => state.wishlist.wishlist;
export const WishlistCount = (state) => state.wishlist.wishlistCount;
export const getCountWishlist = (state) => state.wishlist.wishlistCount;

// selectors
export const allWishlist = createSelector(Wishlist, (state) => state);
export const wishlistCount = createSelector(WishlistCount, (state) => state);

export default wishlistSlice.reducer;
