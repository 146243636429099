/* eslint-disable import/no-cycle */
import React, { useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CONFIG_CART_PROD, OOS } from 'Utils/Strings';
import { addToWishlist } from 'Features/wishlist/wishlistSlice';
import { removeFromCart } from 'Features/cart/cartSlice';
import { notification } from 'Utils/Toast';
import { changeTrigger } from 'Features/login';
import { wishlistImgUrl } from 'Utils/filterImageUrl';
import { X } from 'phosphor-react';
import { setLoading } from 'Features/GlobalLoading/LoadingSlice';
import { pushToDataLayer } from 'Utils/pushToDataLayer';
import Media from './Media';
import './DropdownProducts.css';
import '../Cart/FreeProduct.css';

function DropdownProducts({ product, cart }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const swatch_data_base_url = useSelector((state) => state.storeConfig.swatch_data_base_url);
  const currency = useSelector((state) => state.storeConfig.base_currency_code);

  const [isProcessing, setIsProcessing] = useState(false);

  const truncateVariantText = (text) => {
    if (!text) return '';
    const variantText = text.split(' ');
    return variantText.length <= 3 ? text : `${variantText.slice(0, 3).join(' ')}...`;
  };

  let queryStringCuid = null;
  const Cuid = product?.configurable_options?.[0]?.configurable_product_option_value_uid;

  if (Cuid) {
    // Using URLSearchParams to construct the query string
    const queryParams = new URLSearchParams();
    queryParams.append('Cuid', Cuid);
    queryStringCuid = queryParams.toString();
  }

  const fPrice = useMemo(() => {
    if (product?.applied_tier_price) {
      return product?.applied_tier_price?.tier_price?.value;
    }
    return product.__typename === CONFIG_CART_PROD
      ? product.configured_variant.price_range?.maximum_price.final_price.value
      : product.product.price_range?.maximum_price.final_price.value;
  }, [product]);

  const rPrice = useMemo(
    () =>
      product.__typename === CONFIG_CART_PROD
        ? product.configured_variant.price_range?.maximum_price.regular_price.value
        : product.product.price_range?.maximum_price.regular_price.value,
    [product],
  );

  const dis = useMemo(() => {
    if (product?.applied_tier_price) {
      return product?.applied_tier_price?.discount?.percent_off;
    }
    return product.__typename === CONFIG_CART_PROD
      ? product.configured_variant.price_range?.maximum_price.discount.percent_off
      : product.product.price_range?.maximum_price.discount.percent_off;
  }, [product]);

  const MoveToWishlist = async () => {
    const { sku } = product.product;
    const selectedOptions =
      product.__typename === CONFIG_CART_PROD &&
      product?.configurable_options[0]?.option_label === 'Color' &&
      product?.configurable_options[1]?.option_label === 'Size'
        ? [
            `"${product.configurable_options[0].configurable_product_option_value_uid}"`,
            `"${product.configurable_options[1].configurable_product_option_value_uid}"`,
          ]
        : product.__typename === CONFIG_CART_PROD &&
          product?.configurable_options[0]?.option_label === 'Color'
        ? [`"${product.configurable_options[0].configurable_product_option_value_uid}"`]
        : product.__typename === CONFIG_CART_PROD &&
          product?.configurable_options[0]?.option_label === 'Size'
        ? [`"${product.configurable_options[0].configurable_product_option_value_uid}"`]
        : [];
    try {
      dispatch(setLoading(true));
      await dispatch(addToWishlist({ sku, selectedOptions })).unwrap();
      dispatch(removeFromCart(product?.uid)).unwrap();
      dispatch(setLoading(false));
      notification.success('Item moved to wishlist successfully');
      window.gtag('event', 'movedToWishlistFromCart', {
        currency,
        value: product.product.price_range?.maximum_price.final_price.value,
        name: product.product.brand_label,
        prodId: product?.product?.uid,
        skuId: product?.product?.sku,
        prodName: product?.product?.name,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={
        product?.prices?.price?.value === 0
          ? 'dropdownCartProd order-first free'
          : product?.product?.stock_status === OOS ||
            product?.configured_variant?.stock_status === OOS
          ? 'dropdownCartProd order-last'
          : 'dropdownCartProd'
      }
    >
      <div
        className='upperWrapper'
        role='button'
        tabIndex={0}
        onClick={() => {
          if (isProcessing) return;
          navigate(
            `/${product?.product?.brand_label
              ?.replace(/[^a-zA-Z0-9]/g, '-')
              .replace(/-{2,}/g, '-')
              .toLowerCase()}/${product?.product?.sku} ${
              queryStringCuid ? `?${queryStringCuid}` : ''
            }`,
          );
        }}
      >
        <div className='imgWrapper'>
          <Media
            src={
              product?.__typename === CONFIG_CART_PROD
                ? wishlistImgUrl(product, 'configured_variant')?.url
                : product?.product?.thumbnail?.url
            }
            alt={product.product.thumbnail.label}
          />
        </div>
        <div className='w-100 d-grid imgWrapper-info'>
          <div className='d-flex justify-content-between'>
            <h4>{product.product.brand_label || 'brand'}</h4>
            <div
              role='button'
              tabIndex={0}
              className='position-relative'
              style={{ marginRight: '15px' }}
              onClick={(e) => {
                if (isProcessing) return;

                setIsProcessing(true);

                e.stopPropagation();
                if (cart.some((item) => item.uid === product.uid)) {
                  dispatch(removeFromCart(product.uid))
                    .unwrap()
                    .then((res) => {
                      if (res?.__typename === 'RemoveItemFromCartOutput') {
                        notification.success('Item removed from cart successfully!');
                        window.dataLayer.push({ ecommerce: null });
                        pushToDataLayer({
                          event: 'RemoveFromCart',
                          ecommerce: {
                            prid: product?.product?.sku,
                            prqt: 1,
                            image: product.product?.thumbnail?.url,
                            product_name: product?.product?.name,
                            category: product.product?.categories?.[2]?.name,
                            brand: product.product.brand_label,
                            selling_price:
                              product.product.price_range?.minimum_price?.regular_price?.value,
                          },
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    })
                    .finally(() => {
                      setIsProcessing(false);
                    });
                }
              }}
            >
              <X size={15} color='#000' className='position-absolute' weight='thin' />
            </div>
          </div>
          <p className='cartdrop-productname'>
            {' '}
            {product?.configured_variant?.name || product?.product?.name}
          </p>
          <div className='d-flex gap-2 wish-pop dropdownFreeItem'>
            {product?.prices?.price?.value === 0 ? (
              <div>
                <span className='freeItem-tag'>FREE</span>{' '}
                <span className='freeItem-price'>
                  {new Intl.NumberFormat('en-us', {
                    style: 'currency',
                    currency:
                      product.product.price_range?.maximum_price.regular_price.currency || 'INR',
                    maximumFractionDigits: 0,
                  })
                    .format(product.product.price_range?.maximum_price.regular_price.value)
                    .replace(/^(\D+)/, '$1 ')}
                </span>
              </div>
            ) : (
              <>
                <span className='fPrice'>
                  {new Intl.NumberFormat('en-us', {
                    style: 'currency',
                    currency:
                      product.product.price_range?.maximum_price.regular_price.currency || 'INR',
                    maximumFractionDigits: 0,
                  })
                    .format(Math.round(fPrice))
                    .replace(/^(\D+)/, '$1 ')}
                </span>
                {dis !== 0 && (
                  <span className='rPrice'>
                    {new Intl.NumberFormat('en-us', {
                      style: 'currency',
                      currency:
                        product.product.price_range?.maximum_price.regular_price.currency || 'INR',
                      maximumFractionDigits: 0,
                    })
                      .format(Math.round(rPrice))
                      .replace(/^(\D+)/, '$1 ')}
                  </span>
                )}
                {dis !== 0 && <span className='dis'>{Math.round(dis)}% OFF</span>}
              </>
            )}
          </div>
          <div className='d-flex justify-content-between bottomWrapper'>
            <div className='d-flex gap-3'>
              <span>Qty: {product.quantity}</span>
              <span
                className={`d-flex justify-content-start ${
                  product.__typename !== CONFIG_CART_PROD && 'opacity-0'
                }`}
              >
                {product.configurable_options?.[0].swatch_data ? (
                  <span className='configOptions'>
                    {/#/.test(product.configurable_options?.[0].swatch_data?.value) ? (
                      <div
                        className='color'
                        style={{
                          backgroundColor: product.configurable_options?.[0]?.swatch_data?.value,
                        }}
                      />
                    ) : (
                      <LazyLoadImage
                        className='color'
                        src={`${swatch_data_base_url}${product.configurable_options?.[0]?.swatch_data?.value}`}
                      />
                    )}
                    <span className='cart-name'>
                      {truncateVariantText(product.configurable_options?.[0].value_label)}
                    </span>
                  </span>
                ) : (
                  <span className='configOptions'>
                    {truncateVariantText(product.configurable_options?.[0].value_label)}
                  </span>
                )}
              </span>
            </div>
            <span
              className={`d-flex justify-content-end ${
                product?.prices?.price?.value === 0 && 'd-none'
              }`}
            >
              <button
                type='button'
                onClick={(e) => {
                  if (!localStorage.getItem('token')) {
                    return dispatch(changeTrigger());
                  }
                  e.stopPropagation();
                  MoveToWishlist();
                }}
              >
                Move To Wishlist
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DropdownProducts;
