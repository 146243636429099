/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  isNewCompany: false,
  mobileNumber: '',
  email: '',
  gstin: '',
  entityName: '',
  principalAddress: '',
  address: {},
  businessType: '',
  panCardNumber: '',
  userType: '',
  uploadFile: '',
  panDateOfIssue: '',
  entityType: null,
  companyId: null,
  otp: '',
};

const signInSlice = createSlice({
  name: 'signInSignUp',
  initialState,
  reducers: {
    setIsNewCompany: (state, action) => {
      state.isNewCompany = action.payload;
    },
    setMobileNumber: (state, action) => {
      state.mobileNumber = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setGSTIn: (state, action) => {
      state.gstin = action.payload;
    },
    setEntityName: (state, action) => {
      state.entityName = action.payload;
    },
    setPrincipalAddress: (state, action) => {
      state.principalAddress = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setBusinessType: (state, action) => {
      state.businessType = action.payload;
    },
    setPanCardNumber: (state, action) => {
      state.panCardNumber = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setPanCardFile: (state, action) => {
      state.panCardFile = action.payload;
    },
    setPanDateOfIssue: (state, action) => {
      state.panDateOfIssue = action.payload;
    },
    setEntityType: (state, action) => {
      state.entityType = action.payload;
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
  },
});

export const {
  setIsNewCompany,
  setMobileNumber,
  setEmail,
  setGSTIn,
  setEntityName,
  setPrincipalAddress,
  setAddress,
  setBusinessType,
  setPanCardNumber,
  setUserType,
  setPanCardFile,
  setPanDateOfIssue,
  setEntityType,
  setCompanyId,
  setOtp,
} = signInSlice.actions;

export default signInSlice.reducer;
